import { getAccount, getAccountId } from './common';
import { getApi, postApi } from './common/apis';

export default class ProfileService {
    async getProfile() {
        var profileData = await getApi('v1/partner-profile')
        return profileData
    }

    async getProfileById() {
        const accountId = getAccountId();
        var profileData = await getApi(`v2/partner-profile?id=${accountId}`)
        return profileData
    }

    async updateProfile(payload) {
        const accountId = getAccountId();
        var url = getAccount() ? `v2/partner-profile?id=${accountId}` : `v2/partner-profile`;
        var profileData = await postApi(url, JSON.parse(JSON.stringify(payload)))
        return profileData
    }
}
