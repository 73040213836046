<template>
    <Toast />
    <div>
        <div class="p-d-flex p-jc-between">
            <p class="profile-title">Partner Profile</p>
            <div v-if="show">
                <Button label="Cancel" class="p-button-outlined button" @click="cancel" />
                <Button label="Save" class="button" @click="savePartnerProfile" />
                <FloatingSaveCancel @save="savePartnerProfile" @cancel="cancel" />
            </div>
        </div>

        <Accordion :multiple="true" :activeIndex="[0, 1]">
            <AccordionTab header="Basic information - Company profile information">
                <div class="p-grid p-fluid">
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Company name</p>
                        <InputText
                            type="text"
                            v-model="v$.Company_name.$model"
                            @input="inputChange()"
                            placeholder="Enter company name"
                            @keypress="isLetter($event)"
                        />
                        <p v-for="error of v$.Company_name.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the value' }}
                        </p>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <p class="field-title">
                            Company logo
                            <Tooltip
                                description="Recommended size 250*100px. Upload an image well suited for white/light backgrounds."
                            />
                        </p>
                        <FileUpload
                            @saved="onSaved"
                            :isImage="isImage"
                            :isAttachment="isAttachment"
                            :id="partnerId"
                            :fieldName="companyLogoName"
                            :logoUrl="logoUrl"
                        />
                    </div>

                    <div class="p-col-12 p-md-6" v-if="isAdmin">
                        <p class="field-title">Quickbooks vendor ID</p>
                        <InputText
                            v-model="v$.Quickbooks_vendor_ID.$model"
                            @input="inputChange()"
                            placeholder="Enter quickbooks vendor ID"
                        />
                        <p v-for="error of v$.Quickbooks_vendor_ID.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter numeric value of maximum length 15 characters' }}
                        </p>
                    </div>

                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Website</p>
                        <InputText type="text" v-model="v$.Website.$model" @input="inputChange()" placeholder="Enter Website" />
                        <p v-for="error of v$.Website.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter a valid URL' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">
                            Primary contact person
                            <Tooltip
                                description="This is the person at your firm best positioned to answer questions and make decisions during the RFQ and statement-of-work processes."
                            />
                        </p>
                        <InputText
                            type="text"
                            v-model="v$.Primary_contact_person.$model"
                            @input="inputChange()"
                            placeholder="Enter primary contact person"
                        />
                        <p v-for="error of v$.Primary_contact_person.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the value' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Primary contact title</p>
                        <InputText
                            type="text"
                            v-model="v$.Primary_contact_title.$model"
                            @input="inputChange()"
                            placeholder="Enter primary contact title"
                        />
                        <p v-for="error of v$.Primary_contact_title.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the value' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Primary contact email</p>
                        <InputText
                            type="text"
                            v-model="v$.Primary_contact_email.$model"
                            @input="inputChange()"
                            placeholder="Enter primary contact email"
                        />
                        <p v-for="error of v$.Primary_contact_email.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the valid email' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Primary contact phone number</p>
                        <InputText
                            type="text"
                            v-model="v$.Phone_number.$model"
                            @input="inputChange()"
                            placeholder="Enter primary contact phone number"
                            @keypress="isNumber($event)"
                        />
                        <p v-for="error of v$.Phone_number.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter a valid phone number' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">
                            Escalation contact name
                            <Tooltip
                                description="This is the person to whom we should bring issues, questions, and decisions if we are unable to reach the primary contact."
                            />
                        </p>
                        <InputText
                            type="text"
                            v-model="v$.escalation_contact_name.$model"
                            @input="inputChange()"
                            placeholder="Enter escalation contact name"
                        />
                        <p v-for="error of v$.escalation_contact_name.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the value' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Escalation contact title</p>
                        <InputText
                            type="text"
                            v-model="v$.escalation_contact_title.$model"
                            @input="inputChange()"
                            placeholder="Enter escalation contact title"
                        />
                        <p v-for="error of v$.escalation_contact_title.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the value' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Escalation contact email</p>
                        <InputText
                            type="text"
                            v-model="v$.escalation_contact_email.$model"
                            @input="inputChange()"
                            placeholder="Enter escalation contact email"
                        />
                        <p v-for="error of v$.escalation_contact_email.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter the valid email' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Escalation phone number</p>
                        <InputText
                            type="text"
                            v-model="v$.escalation_contact_phone.$model"
                            @input="inputChange()"
                            placeholder="Enter escalation phone number"
                            @keypress="isNumber($event)"
                        />
                        <p v-for="error of v$.escalation_contact_phone.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter a valid phone number' }}
                        </p>
                    </div>
                    <div class="p-col-12 p-md-6">
                        <p class="field-title">Please list the number of years your organization has been in business</p>
                        <InputText
                            v-model="v$.business_countries.$model"
                            @input="inputChange()"
                            placeholder="Enter the number of years"
                        />
                        <p v-for="error of v$.business_countries.$errors" :key="error.$uid" class="error-msg">
                            {{ 'Please enter numeric value of maximum length 15 characters' }}
                        </p>
                    </div>
                </div>
                <ProfileAddress :saveInd="saveInd" :locationInfoValues="locationInfoValues" @submit="locationInfoValidation" />
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
import { required, email, maxLength, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import ProfileService from '../../service/ProfileSservice';
import { onMounted } from '@vue/runtime-core';
import { getAccount, getAccountId } from '../../service/common';
import Tooltip from '../Tooltip.vue';
import ProfileAddress from './ProfileAddress.vue';
import CountryService from '../../service/CountryService';
import FileUpload from '../common/FileUpload.vue';
import FloatingSaveCancel from '../common/FloatingSaveCancel.vue';

const customDomainCheck = (value) => {
    if (value) {
        //#31wdfdn -- decided this was always going to be a problem, so always return true.
        //            every regex variation has flaws
        return true;

        // PREVIOUS VERSION: didn't like any path, or ".outsourcing" vs ".com"
        // const regex = new RegExp(/(?:(?:[a-zA-Z0-9])(?:[-.][a-zA-Z0-9])?)+(?:\.[a-z]{2,6})+$/);
        // return regex.test(value);
    }
    return true;
};

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        ProfileAddress,
        Tooltip,
        FileUpload,
        FloatingSaveCancel,
    },
    data() {
        return {
            Company_name: '',
            Quickbooks_vendor_ID: null,
            Website: '',
            Primary_contact_person: '',
            Primary_contact_title: '',
            Primary_contact_email: '',
            escalation_contact_name: '',
            escalation_contact_title: '',
            escalation_contact_email: '',
            escalation_contact_phone: '',
            business_countries: null,
            Phone_number: null,
            companyLogoName: '',
            profileForm: {},
            responseData: [],
            show: false,
            isAdmin: false,
            isImage: true,
            isAttachment: false,
            partnerId: null,
            locationInfoValues: null,
            saveInd: false,
            countries: null,
            // selectedCountries: [],
            logoUrl: null,
            mappedCountry: [],
            fileName: null,
            deleteFile: false,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            Company_name: { required, $autoDirty: true },
            Quickbooks_vendor_ID: this.isAdmin ? { positiveInt } : {},
            Website: { customDomainCheck },
            Primary_contact_person: { $autoDirty: true },
            Primary_contact_title: { $autoDirty: true },
            Primary_contact_email: { email, $autoDirty: true },
            escalation_contact_name: { $autoDirty: true },
            escalation_contact_title: { $autoDirty: true },
            escalation_contact_email: { email, $autoDirty: true },
            escalation_contact_phone: { maxLength: maxLength(25), minLength: minLength(10), $autoDirty: true },
            business_countries: { $autoDirty: true, positiveInt },
            Phone_number: { maxLength: maxLength(25), minLength: minLength(10) },
        };
    },
    created() {
        this.profileService = new ProfileService();
        this.countryService = new CountryService();
        onMounted(() => {
            this.getProfile();
            this.isAdmin = getAccount();
            this.countryService.getCountries().then((countries) => {
                this.countries = countries;
            });
        });
    },
    watch: {
        $route() {
            this.partnerId = getAccountId();
        },
        partnerId() {
            this.profileService = new ProfileService();
            this.getProfile();
            this.isAdmin = getAccount();
        },
    },
    methods: {
        savePartnerProfile() {
            this.v$.$touch();
            if (this.v$.$error === false) {
                this.submitProfile();
            } else {
                this.v$.$errors.forEach((d) => {
                    if (d.$property === 'Company_name') {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error while creating user',
                            detail: 'Please update mandatory field',
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error while creating user',
                            detail: 'Please enter a valid input',
                            life: 3000,
                        });
                    }
                });
            }
        },
        locationInfoValidation(silentErrors, locationInfoValuesData) {
            this.locationInfoValues = locationInfoValuesData;
            this.inputChange();
        },
        stringCheck(value) {
            if (value !== null && value !== undefined) {
                return value.toString().trim();
            }
        },
        inputChange() {
            this.show = true;
        },
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode);
            if (!/[^a-zA-Z0-9 ,.]/.test(char)) return true;
            else e.preventDefault();
        },
        isNumber(e) {
            let char = String.fromCharCode(e.keyCode);
            if (!/[^0-9+]/.test(char)) return true;
            else e.preventDefault();
        },
        phoneKeyUp() {
            if (this.escalation_contact_phone !== '' || this.Phone_number !== '') {
                this.show = true;
            }
        },
        submitProfile() {
            this.show = false;
            let payload = {};

            var filteredData = this.responseData.filter((element) => element.field_name !== 'company_logo');

            filteredData.forEach((element) => {
                if (element.field_name == 'name') {
                    element.json_value = this.stringCheck(this.Company_name);
                    this.Company_name = this.stringCheck(this.Company_name);
                }
                if (element.field_name == 'website') {
                    element.json_value = this.Website;
                }
                if (element.field_name == 'quickbooks_vendor_id' && this.isAdmin) {
                    element.json_value = this.Quickbooks_vendor_ID;
                }
                if (element.field_name == 'phone') {
                    element.json_value = this.Phone_number;
                }
                if (element.field_name == 'primary_contact_name') {
                    element.json_value = this.stringCheck(this.Primary_contact_person);
                    this.Primary_contact_person = this.stringCheck(this.Primary_contact_person);
                }
                if (element.field_name == 'primary_contact_title') {
                    element.json_value = this.stringCheck(this.Primary_contact_title);
                    this.Primary_contact_title = this.stringCheck(this.Primary_contact_title);
                }
                if (element.field_name == 'email') {
                    element.json_value = this.Primary_contact_email;
                }
                if (element.field_name == 'escalation_phone_number') {
                    element.json_value = this.escalation_contact_phone;
                }
                if (element.field_name == 'escalation_contact_name') {
                    element.json_value = this.stringCheck(this.escalation_contact_name);
                    this.escalation_contact_name = this.stringCheck(this.escalation_contact_name);
                }
                if (element.field_name == 'escalation_contact_email') {
                    element.json_value = this.escalation_contact_email;
                }
                if (element.field_name == 'escalation_contact_title') {
                    element.json_value = this.stringCheck(this.escalation_contact_title);
                    this.escalation_contact_title = this.stringCheck(this.escalation_contact_title);
                }
                if (element.field_name == 'locations') {
                    this.locationInfoValues?.forEach((ele) => delete ele.countryName);
                    element.json_value = this.locationInfoValues;
                }
                if (element.field_name == 'business_years') {
                    element.json_value = parseInt(this.business_countries);
                }
            });

            filteredData.forEach((ele) => {
                payload[ele.field_name] = ele.json_value === undefined ? null : ele.json_value;
            });

            this.profileService.updateProfile(payload).then((res) => {
                if (res.status === 200) {
                    this.show = false;
                    this.$toast.add({ severity: 'success', summary: res.data, detail: '', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error while creating user', detail: res.data, life: 3000 });
                }
            });
        },
        cancel() {
            this.getProfile();
            this.show = false;
        },
        async getProfile() {
            var data = await this.profileService.getProfileById();
            if (data.status === 200) {
                var resData = data.data;
                resData.forEach((element) => {

                    // 8685m4xxm -- weird newline characters at end of emails causing validation trouble
                    if(typeof element.json_value === 'string') {
                        element.json_value = element.json_value.trim();  // trim() does everything!
                    }  

                    if (element.field_name == 'name') {
                        this.Company_name = element.json_value;
                    }
                    if (element.field_name == 'website') {
                        this.Website = element.json_value;
                    }
                    if (element.field_name == 'quickbooks_vendor_id' && this.isAdmin) {
                        this.Quickbooks_vendor_ID = element.json_value;
                    }
                    if (element.field_name == 'phone') {
                        this.Phone_number = element.json_value;
                    }
                    if (element.field_name == 'primary_contact_name') {
                        this.Primary_contact_person = element.json_value;
                    }
                    if (element.field_name == 'primary_contact_title') {
                        this.Primary_contact_title = element.json_value;
                    }
                    if (element.field_name == 'email') {
                        this.Primary_contact_email = element.json_value;
                    }
                    if (element.field_name == 'escalation_phone_number') {
                        this.escalation_contact_phone = element.json_value;
                    }
                    if (element.field_name == 'escalation_contact_name') {
                        this.escalation_contact_name = element.json_value;
                    }
                    if (element.field_name == 'escalation_contact_email') {
                        this.escalation_contact_email = element.json_value;
                    }
                    if (element.field_name == 'escalation_contact_title') {
                        this.escalation_contact_title = element.json_value;
                    }
                    if (element.field_name == 'locations') {
                        this.locationInfoValues = element.json_value;
                    }
                    if (element.field_name == 'business_years') {
                        this.business_countries = element.json_value;
                    }
                    if (element.field_name == 'company_logo') {
                        this.companyLogoName = element.field_name;
                        this.partnerId = getAccountId();
                        this.logoUrl = element?.other?.attachments[0] || null;
                        if (this.logoUrl) {
                            var splitLink = this.logoUrl?.split('/');
                            this.fileName = splitLink[splitLink.length - 1];
                        }
                    }
                });
                return (this.responseData = resData);
            } else {
                (this.Company_name = ''),
                    (this.Quickbooks_vendor_ID = ''),
                    (this.Website = ''),
                    (this.Primary_contact_name = ''),
                    (this.Phone_number = '');
            }
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.uploadButton {
    display: none;
}

.uploadLabel {
    border: 1px solid #949ba1;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;
    color: rgb(109, 107, 107);
    width: 30rem;
    margin-left: 1rem;
    background-image: url('../../assets/Upload.svg');
    background-position: 95% center;
    background-repeat: no-repeat;
}

::v-deep(.p-accordion-header) {
    font-size: large;
}

::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link),
::v-deep(.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link):hover {
    background-color: rgb(228, 228, 228);
    font-weight: 500;
    margin-top: 1rem;
}

::v-deep(.eden-color) {
    background: #0f5b60;
}

::v-deep(.p-button) {
    background: #00796b !important;
    width: 8rem !important;
    border: 2px solid #00796b !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
    margin-right: 1.25rem;
}

.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}
::v-deep(.p-inputtext) {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.link a {
    color: blue;
}

.attach-file {
    color: #126a70;
    margin-left: 3rem;
}
</style>
