import { getAccountId } from './common';
import { postApi, getApi } from './common/apis';

export default class AnswerBankService {
    async getAnswerBank() {
        const accountId = getAccountId();
        try {
            var response = await getApi(`v3/answer-bank?id=${accountId}`);

            return await response;
        } catch (err) {
            console.error('Error', err.stack);
            return err.response;
        }
    }

    async postAnswerBank(payload, isAdmin) {
        const accountId = getAccountId();
        const path = isAdmin ? `v3/answer-bank?id=${accountId}` : `v3/answer-bank`;
        var answerBankData = await postApi(path, payload);
        return answerBankData;
    }
}
