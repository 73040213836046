<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.annual_interactions_per_demand_phone }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.phoneInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand phone"
            />
            <p v-for="error of v$.phoneInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WorkVolumeLabel.annual_interactions_per_demand_chat }}</p>

            <InputText
                class="input-text"
                v-model="v$.chatInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand chat"
            />
            <p v-for="error of v$.chatInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.annual_interactions_per_demand_email_webform }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.emailInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand email"
            />
            <p v-for="error of v$.emailInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.annual_interactions_per_demand_social }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.socialmediaInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand social media"
            />
            <p v-for="error of v$.socialmediaInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WorkVolumeLabel.annual_interactions_per_demand_sms }}</p>

            <InputText
                class="input-text"
                v-model="v$.smsInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand sms"
            />
            <p v-for="error of v$.smsInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.annual_interactions_per_demand_video }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.videoInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand video"
            />
            <p v-for="error of v$.videoInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.annual_interactions_per_demand_other }}
            </p>

            <InputText
                class="input-text"
                v-model="v$.otherChannelsInteract.$model"
                @input="changeHandler"
                placeholder="Enter annual interactions per demand other channels"
            />
            <p v-for="error of v$.otherChannelsInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.interactions_inbound }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.inboundInteract.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of inbound interactions"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.inboundInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ WorkVolumeLabel.interactions_outbound }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.outboundInteract.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of interactions are outbound"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.outboundInteract.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WorkVolumeLabel.average_score_csat }}</p>

            <InputText
                class="input-text"
                v-model="v$.CSATScore.$model"
                @input="changeHandler"
                placeholder="Enter average score csat"
            />
            <p v-for="error of v$.CSATScore.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WorkVolumeLabel.average_score_sla_compliance }}</p>

            <InputText
                class="input-text"
                v-model="v$.SLAScore.$model"
                @input="changeHandler"
                placeholder="Enter average score sla compliance"
            />
            <p v-for="error of v$.SLAScore.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { useVuelidate } from '@vuelidate/core';

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

const positiveDecimal = (value) => {
    if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    props: ['saveInd', 'WorkVolumeValues'],
    data() {
        return {
            WorkVolumeLabel: answerBankLabel.WorkVolume,
            phoneInteract: null,
            chatInteract: null,
            emailInteract: null,
            socialmediaInteract: null,
            smsInteract: null,
            videoInteract: null,
            otherChannelsInteract: null,
            inboundInteract: null,
            outboundInteract: null,
            CSATScore: null,
            SLAScore: null,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            phoneInteract: { positiveInt },
            chatInteract: { positiveInt },
            emailInteract: { positiveInt },
            socialmediaInteract: { positiveInt },
            smsInteract: { positiveInt },
            videoInteract: { positiveInt },
            otherChannelsInteract: { positiveInt },
            inboundInteract: { positiveInt },
            outboundInteract: { positiveInt },
            CSATScore: { positiveDecimal },
            SLAScore: { positiveDecimal },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        WorkVolumeValues() {
            if (this.WorkVolumeValues === null) {
                this.phoneInteract = null;
                this.chatInteract = null;
                this.emailInteract = null;
                this.socialmediaInteract = null;
                this.smsInteract = null;
                this.videoInteract = null;
                this.otherChannelsInteract = null;
                this.inboundInteract = null;
                this.outboundInteract = null;
                this.CSATScore = null;
                this.SLAScore = null;
                return;
            }
            try {
                this.WorkVolumeValues?.forEach((element) => {
                    if (element.field_name === 'annual_interactions_per_demand_phone') this.phoneInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_chat') this.chatInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_email_webform')
                        this.emailInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_social')
                        this.socialmediaInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_sms') this.smsInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_video') this.videoInteract = element.json_value;
                    if (element.field_name === 'annual_interactions_per_demand_other')
                        this.otherChannelsInteract = element.json_value;
                    if (element.field_name === 'interactions_inbound') this.inboundInteract = element.json_value;
                    if (element.field_name === 'interactions_outbound') this.outboundInteract = element.json_value;
                    if (element.field_name === 'average_score_csat') this.CSATScore = element.json_value;
                    if (element.field_name === 'average_score_sla_compliance') this.SLAScore = element.json_value;
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        validationCall() {
            this.WorkVolumeValues?.forEach((element) => {
                if (element.field_name === 'annual_interactions_per_demand_phone')
                    element.json_value = parseInt(this.phoneInteract);
                if (element.field_name === 'annual_interactions_per_demand_chat')
                    element.json_value = parseInt(this.chatInteract);
                if (element.field_name === 'annual_interactions_per_demand_email_webform')
                    element.json_value = parseInt(this.emailInteract);
                if (element.field_name === 'annual_interactions_per_demand_social')
                    element.json_value = parseInt(this.socialmediaInteract);
                if (element.field_name === 'annual_interactions_per_demand_sms') element.json_value = parseInt(this.smsInteract);
                if (element.field_name === 'annual_interactions_per_demand_video')
                    element.json_value = parseInt(this.videoInteract);
                if (element.field_name === 'annual_interactions_per_demand_other')
                    element.json_value = parseInt(this.otherChannelsInteract);
                if (element.field_name === 'interactions_inbound') element.json_value = parseInt(this.inboundInteract);
                if (element.field_name === 'interactions_outbound') element.json_value = parseInt(this.outboundInteract);
                if (element.field_name === 'average_score_csat') element.json_value = parseFloat(this.CSATScore);
                if (element.field_name === 'average_score_sla_compliance') element.json_value = parseFloat(this.SLAScore);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.WorkVolumeValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
