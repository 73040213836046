<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.projmgmt_dedicated_launch_pm }}</p>
            <Dropdown
                v-model="v$.PMLauch.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select dedicated launch pm"
            />
            <p v-for="error of v$.PMLauch.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.projmgmt_standard_template }}</p>
            <Dropdown
                v-model="v$.PMStandardTemp.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select standard template"
            />
            <p v-for="error of v$.PMStandardTemp.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.wfm_demand_planning_tool }}</p>
            <Textarea
                class="input-text"
                v-model="v$.WFMDemandTool.$model"
                name="WFMDemandTool"
                @input="changeHandler"
                placeholder="Enter demand planning tool"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="WFMDemandToolName"
                :logoUrl="WFMDemandToolURL"
            />
            <p v-for="error of v$.WFMDemandTool.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.wfm_capacity_planning_tool }}</p>
            <Textarea
                class="input-text"
                v-model="v$.WFMCapacityTool.$model"
                name="WFMCapacityTool"
                @input="changeHandler"
                placeholder="Enter capacity planning tool"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="WFMCapacityToolName"
                :logoUrl="WFMCapacityToolURL"
            />
            <p v-for="error of v$.WFMCapacityTool.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.projmgmt_process }}</p>
            <Textarea
                class="input-text"
                v-model="v$.impleProcess.$model"
                name="impleProcess"
                @input="changeHandler"
                placeholder="Enter  implementation process and typical implementation timeline"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="impleProcessName"
                :logoUrl="impleProcessURL"
            />
            <p v-for="error of v$.impleProcess.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.change_process }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalProcess.$model"
                name="internalProcess"
                @input="changeHandler"
                placeholder="Enter your internal processes for change management"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalProcessName"
                :logoUrl="internalProcessURL"
            />
            <p v-for="error of v$.internalProcess.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.wfm_process_description }}</p>
            <Textarea
                class="input-text"
                v-model="v$.WFMProcess.$model"
                name="WFMProcess"
                @input="changeHandler"
                placeholder="Enter process description"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="WFMProcessName"
                :logoUrl="WFMProcessURL"
            />
            <p v-for="error of v$.WFMProcess.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ WFManagementLabel.projmgmt_template }}</p>
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="false"
                :id="partnerId"
                :fieldName="projmgmtTemplateName"
                :logoUrl="projmgmtTemplateURL"
            />
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'WFManagementValues'],
    data() {
        return {
            WFManagementLabel: answerBankLabel.WFManagement,
            WFMDemandTool: null,
            WFMCapacityTool: null,
            WFMProcess: null,
            PMLauch: null,
            impleProcess: null,
            PMStandardTemp: null,
            internalProcess: null,
            partnerId: null,
            WFMDemandToolName: null,
            WFMCapacityToolName: null,
            WFMProcessName: null,
            impleProcessName: null,
            internalProcessName: null,
            WFMDemandToolURL: null,
            WFMCapacityToolURL: null,
            WFMProcessURL: null,
            impleProcessURL: null,
            internalProcessURL: null,
            projmgmtTemplateName: null,
            projmgmtTemplateURL: null,
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            WFMDemandTool: { $autoDirty: true },
            WFMCapacityTool: { $autoDirty: true },
            WFMProcess: { $autoDirty: true },
            PMLauch: { minLength: minLength(1) },
            impleProcess: { $autoDirty: true },
            PMStandardTemp: { minLength: minLength(1) },
            internalProcess: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        WFManagementValues() {
            if (this.WFManagementValues === null) {
                this.WFMDemandTool = null;
                this.WFMCapacityTool = null;
                this.WFMProcess = null;
                this.PMLauch = null;
                this.impleProcess = null;
                this.PMStandardTemp = null;
                this.internalProcess = null;
                return;
            }
            try {
                this.WFManagementValues?.forEach((element) => {
                    if (element.field_name === 'wfm_demand_planning_tool') {
                        this.WFMDemandToolName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.WFMDemandTool = element.json_value;
                        this.WFMDemandToolURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'wfm_capacity_planning_tool') {
                        this.WFMCapacityToolName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.WFMCapacityTool = element.json_value;
                        this.WFMCapacityToolURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'wfm_process_description') {
                        this.WFMProcessName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.WFMProcess = element.json_value;
                        this.WFMProcessURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'projmgmt_dedicated_launch_pm') this.PMLauch = element.json_value;
                    if (element.field_name === 'projmgmt_process') {
                        this.impleProcessName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.impleProcess = element.json_value;
                        this.impleProcessURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'projmgmt_standard_template') this.PMStandardTemp = element.json_value;
                    if (element.field_name === 'projmgmt_template') {
                        this.projmgmtTemplateName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.projmgmtTemplateURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'change_process') {
                        this.internalProcessName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalProcess = element.json_value;
                        this.internalProcessURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;

            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.WFManagementValues?.forEach((element) => {
                if (element.field_name === 'wfm_demand_planning_tool') element.json_value = this.stringCheck(this.WFMDemandTool);
                if (element.field_name === 'wfm_capacity_planning_tool')
                    element.json_value = this.stringCheck(this.WFMCapacityTool);
                if (element.field_name === 'wfm_process_description') element.json_value = this.stringCheck(this.WFMProcess);
                if (element.field_name === 'projmgmt_dedicated_launch_pm') element.json_value = this.PMLauch;
                if (element.field_name === 'projmgmt_process') element.json_value = this.stringCheck(this.impleProcess);
                if (element.field_name === 'projmgmt_standard_template') element.json_value = this.PMStandardTemp;
                if (element.field_name === 'change_process') element.json_value = this.stringCheck(this.internalProcess);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.WFManagementValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
