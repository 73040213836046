<template>
    <!-- need to update v-models -->
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">Latest credit score</p>
            <InputText v-model="v$.latestCreditScore.$model" @input="changeEvent" placeholder="Enter latest credit score" />
            <p v-for="error of v$.latestCreditScore.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">Experian pull date</p>
            <Calendar
                id="icon"
                v-model="v$.dateVal.$model"
                @date-select="changeEvent"
                :showIcon="true"
                dateFormat="yy-mm-dd"
                :manualInput="false"
            />
            <p v-for="error of v$.dateVal.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please select the value' }}
            </p>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

export default {
    name: 'CreditScore',
    props: ['saveInd', 'creditScoreValues'],
    data() {
        return {
            latestCreditScore: null,
            dateVal: '',
            intializeOnce: true,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            latestCreditScore: { positiveInt, $autoDirty: true },
            dateVal: {},
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        creditScoreValues() {
            if (this.creditScoreValues === null) {
                this.latestCreditScore = null;
                this.dateVal = null;
                return;
            }
            try {
                this.creditScoreValues?.forEach((element) => {
                    if (element.field_name === 'latest_credit_score') this.latestCreditScore = element.json_value;
                    if (element.field_name === 'experian_pull_date')
                        this.dateVal = element.json_value && new Date(element.json_value);
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeEvent() {
            this.$emit('isValid', true);
            this.validationCall();
        },
        validationCall() {
            this.creditScoreValues?.forEach((element) => {
                if (element.field_name === 'latest_credit_score') element.json_value = parseInt(this.latestCreditScore);
                if (element.field_name === 'experian_pull_date') {
                    const formateDate = new Date(this.dateVal);
                    element.json_value = this.dateVal && formateDate.toLocaleDateString();
                }
            });
            this.$emit('submit', this.v$.$silentErrors.length, this.creditScoreValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-calendar) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-inputtext) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
</style>
