<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_1_1 }}
            </p>
            <Dropdown
                v-model="v$.functionPerformhighPCT.$model"
                @change="changeHandler"
                :options="functionsPerformedOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the highest percent of your business"
                filter
            />
            <p v-for="error of v$.functionPerformhighPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_1_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.largeAmountBusiness.$model"
                    @input="changeHandler"
                    placeholder="Enter the largest amount of business"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.largeAmountBusiness.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_2_1 }}
            </p>
            <Dropdown
                v-model="v$.functionPerformSecPCT.$model"
                @change="changeHandler"
                :options="functionsPerformedOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the second highest percent of your business"
                filter
            />
            <p v-for="error of v$.functionPerformSecPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_2_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.secLargeAmountBusiness.$model"
                    @input="changeHandler"
                    placeholder="Enter the second largest function performed"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.secLargeAmountBusiness.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_3_1 }}
            </p>
            <Dropdown
                v-model="v$.functionPerformThirdPCT.$model"
                @change="changeHandler"
                :options="functionsPerformedOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the third highest percent of your business"
                filter
            />
            <p v-for="error of v$.functionPerformThirdPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.functions_performed_3_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.thirdLargeAmountBusiness.$model"
                    @input="changeHandler"
                    placeholder="Enter the third largest amount of business"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.thirdLargeAmountBusiness.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ FunctionsPerformedLabel.observed_holidays }}
            </p>
            <div class="p-inputgroup">
                <FileUpload
                    @saved="onSaved"
                    :isImage="false"
                    :isAttachment="false"
                    :id="partnerId"
                    :fieldName="ObservedHolidayName"
                    :logoUrl="ObservedHolidayURL"
                />
            </div>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

const positiveDecimal = (value) => {
    if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'FunctionsPerformedValues'],
    data() {
        return {
            FunctionsPerformedLabel: answerBankLabel.FunctionsPerformed,
            functionPerformhighPCT: null,
            largeAmountBusiness: null,
            functionPerformSecPCT: null,
            secLargeAmountBusiness: null,
            functionPerformThirdPCT: null,
            thirdLargeAmountBusiness: null,
            ObservedHolidayName: null,
            ObservedHolidayURL: null,
            partnerId: null,
            functionsPerformedOptions: [
                { name: 'Administrative: Audio File Transcription' },
                { name: 'Administrative: Data Entry' },
                { name: 'Administrative: Document Descriction' },
                { name: 'Administrative: Document Digitization' },
                { name: 'Administrative: Insurance Claims Processing' },
                { name: 'Administrative: Insurance Verification' },
                { name: 'Administrative: Legal Documentation' },
                { name: 'Administrative: Records Management' },
                { name: 'Administrative: Virtual Assistant' },
                { name: 'Customer Service: Answering Services' },
                { name: 'Customer Service: Appointment Setting' },
                { name: 'Customer Service: Content Moderation' },
                { name: 'Customer Service: Customer Success: Account Management' },
                { name: 'Customer Service: Customer Success: Customer Onboarding' },
                { name: 'Customer Service: Customer Success: Journey Mapping' },
                { name: 'Customer Service: Customer Success: Renewals Management' },
                { name: 'Customer Service: Customer Success: Retention' },
                { name: 'Customer Service: Customer Support' },
                { name: 'Customer Service: Fractional Leadership' },
                { name: 'Customer Service: Healthcare Services: Clinical Nurses' },
                { name: 'Customer Service: Healthcare Services: Patient Care' },
                { name: 'Customer Service: Healthcare Services: Remote Monitoring Support' },
                { name: 'Customer Service: Healthcare Services: Triage' },
                { name: 'Customer Service: Help Desk' },
                { name: 'Customer Service: Knowledge Base Management' },
                { name: 'Customer Service: Quality Assurance' },
                { name: 'Customer Service: Reservations and Booking' },
                { name: 'Customer Service: Surveys' },
                { name: 'Customer Service: Technical Support: Tier 1' },
                { name: 'Customer Service: Technical Support: Tier 2' },
                { name: 'Customer Service: Technical Support: Tier 3' },
                { name: 'Customer Service: Warranty Management' },
                { name: 'Finance and Accounting: AP/AR' },
                { name: 'Finance and Accounting: Bookkeeping' },
                { name: 'Finance and Accounting: Business Expense Management' },
                { name: 'Finance and Accounting: Collections' },
                { name: 'Finance and Accounting: Controller Services' },
                { name: 'Finance and Accounting: Customer Billing' },
                { name: 'Finance and Accounting: Financial Planning & Analysis' },
                { name: 'Finance and Accounting: Fractional CFO' },
                { name: 'Finance and Accounting: Loan Origination' },
                { name: 'Finance and Accounting: Payment/Transaction Processing' },
                { name: 'Finance and Accounting: Payroll Management' },
                { name: 'Finance and Accounting: Taxation' },
                { name: 'Human Resources: Affirmative Action Programs' },
                { name: 'Human Resources: Benefits Administration' },
                { name: 'Human Resources: Compensation Services' },
                { name: 'Human Resources: Compliance' },
                { name: 'Human Resources: Employee Relations' },
                { name: 'Human Resources: Executive Search' },
                { name: 'Human Resources: HR Assessments' },
                { name: 'Human Resources: Jobs Analysis/ Job Descriptions' },
                { name: 'Human Resources: Leave Administration' },
                { name: 'Human Resources: Payroll Administration' },
                { name: 'Human Resources: Performance Management' },
                { name: 'Human Resources: Recruiting Services' },
                { name: 'Human Resources: Training and Development' },
                { name: 'Lead Generation & Sales: Channel: Campaign Management' },
                { name: 'Lead Generation & Sales: Channel: Channel Framework & Blueprint Creation' },
                { name: 'Lead Generation & Sales: Channel: Channel Sales Management' },
                { name: 'Lead Generation & Sales: Channel: Channel Sales Support' },
                { name: 'Lead Generation & Sales: Channel: Partner Recruiting' },
                { name: 'Lead Generation & Sales: Closing Deals' },
                { name: 'Lead Generation & Sales: Direct Sales' },
                { name: 'Lead Generation & Sales: Fractional Sales Leadership' },
                { name: 'Lead Generation & Sales: Fundraising' },
                { name: 'Lead Generation & Sales: Inbound Lead Qualification' },
                { name: 'Lead Generation & Sales: Inbound Sales Order Processing' },
                { name: 'Lead Generation & Sales: Inbound Sales Support' },
                { name: 'Lead Generation & Sales: Inbound Upselling' },
                { name: 'Lead Generation & Sales: Lead Nurturing' },
                { name: 'Lead Generation & Sales: Messaging and Copywriting' },
                { name: 'Lead Generation & Sales: Online and Offline Database Generation' },
                { name: 'Lead Generation & Sales: Outbound Appointment Setting' },
                { name: 'Lead Generation & Sales: Outbound Prospecting' },
                { name: 'Lead Generation & Sales: Outbound Qualification' },
                { name: 'Lead Generation & Sales: Sales Order Processing' },
                { name: 'Lead Generation & Sales: Sales Strategy' },
                { name: 'Marketing: Branding' },
                { name: 'Marketing: Campaign Management' },
                { name: 'Marketing: Copy & Content Writing' },
                { name: 'Marketing: Customer Surveys and Reviews Management' },
                { name: 'Marketing: Digital Marketing: Programmatic Marketing' },
                { name: 'Marketing: Digital Marketing: Search Engine Marketing' },
                { name: 'Marketing: Digital Marketing: Search Engine Optimization' },
                { name: 'Marketing: Digital Marketing: Social Media' },
                { name: 'Marketing: Digital Marketing: Website Conversion' },
                { name: 'Marketing: Events / Tradeshows' },
                { name: 'Marketing: Fractional Leadership' },
                { name: 'Marketing: Graphic Design' },
                { name: 'Marketing: Influencer Programs' },
                { name: 'Marketing: Market Research' },
                { name: 'Marketing: Marketing Automation' },
                { name: 'Marketing: Traditional Advertising (Print, Television, Outdoor)' },
                { name: 'Marketing: Video Production' },
                { name: 'Marketing: Web Design' },
                { name: 'Procurement: Category Management' },
                { name: 'Procurement: Contract Management' },
                { name: 'Procurement: Cost Recovery and Invoice Auditing' },
                { name: 'Procurement: Source-to-Contract' },
                { name: 'Procurement: Sourcing Support' },
                { name: 'Procurement: Spend Analysis' },
                { name: 'Procurement: Strategic Sourcing' },
                { name: 'Procurement: Supply Market Intelligence' },
                { name: 'Procurement: Tail-Spend Management' },
                { name: 'Procurement: Tracking and Compliance' },
                { name: 'Professional Services: Consulting' },
                { name: 'Professional Services: Licensed Insurance Agents' },
                { name: 'Professional Services: Other' },
                { name: 'Professional Services: System Integrations' },
                { name: 'Technology Services: RPA' },
                { name: 'Technology Services: Software Development' },
                { name: 'Technology Services: Software QA Testing' },
            ],
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            functionPerformhighPCT: { minLength: minLength(1) },
            largeAmountBusiness: { positiveDecimal },
            functionPerformSecPCT: { minLength: minLength(1) },
            secLargeAmountBusiness: { positiveDecimal },
            functionPerformThirdPCT: { minLength: minLength(1) },
            thirdLargeAmountBusiness: { positiveDecimal },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        FunctionsPerformedValues() {
            if (this.FunctionsPerformedValues === null) {
                this.functionPerformhighPCT = null;
                this.largeAmountBusiness = null;
                this.functionPerformSecPCT = null;
                this.secLargeAmountBusiness = null;
                this.functionPerformThirdPCT = null;
                this.thirdLargeAmountBusiness = null;
                return;
            }
            try {
                this.FunctionsPerformedValues?.forEach((element) => {
                    if (element.field_name === 'functions_performed_1_1') this.functionPerformhighPCT = element.json_value;
                    if (element.field_name === 'functions_performed_1_2') this.largeAmountBusiness = element.json_value;
                    if (element.field_name === 'functions_performed_2_1') this.functionPerformSecPCT = element.json_value;
                    if (element.field_name === 'functions_performed_2_2') this.secLargeAmountBusiness = element.json_value;
                    if (element.field_name === 'functions_performed_3_1') this.functionPerformThirdPCT = element.json_value;
                    if (element.field_name === 'functions_performed_3_2') this.thirdLargeAmountBusiness = element.json_value;
                    if (element.field_name === 'observed_holidays') {
                        this.ObservedHolidayName = element.field_name;
                        this.ObservedHolidayURL = element?.other?.attachments[0] || null;
                        this.partnerId = localStorage.getItem('accountId');
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        validationCall() {
            this.FunctionsPerformedValues?.forEach((element) => {
                if (element.field_name === 'functions_performed_1_1') element.json_value = this.functionPerformhighPCT;
                if (element.field_name === 'functions_performed_1_2') element.json_value = parseFloat(this.largeAmountBusiness);
                if (element.field_name === 'functions_performed_2_1') element.json_value = this.functionPerformSecPCT;
                if (element.field_name === 'functions_performed_2_2')
                    element.json_value = parseFloat(this.secLargeAmountBusiness);
                if (element.field_name === 'functions_performed_3_1') element.json_value = this.functionPerformThirdPCT;
                if (element.field_name === 'functions_performed_3_2')
                    element.json_value = parseFloat(this.thirdLargeAmountBusiness);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.FunctionsPerformedValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
