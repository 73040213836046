<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.leadership_team_tenure }}</p>
            <InputText
                class="input-text"
                v-model="v$.tentureLT.$model"
                @input="changeHandler"
                placeholder="Enter leadership team tenure"
            />
            <p v-for="error of v$.tentureLT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.site_square_feet }}</p>
            <InputText class="input-text" v-model="v$.siteSqrt.$model" @input="changeHandler" placeholder="Enter size of site" />
            <p v-for="error of v$.siteSqrt.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ staffManagementLabel.site_square_feet_per_agent }}
            </p>
            <InputText
                class="input-text"
                v-model="v$.agentWorkSqrt.$model"
                @input="changeHandler"
                placeholder="Enter size of agent workspace"
            />
            <p v-for="error of v$.agentWorkSqrt.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ staffManagementLabel.workstation_unused_capacity }}
                <Tooltip description="This is helpful for the customer to understand your potential on-site capacity." />
            </p>
            <InputText
                class="input-text"
                v-model="v$.workStationCapacity.$model"
                @input="changeHandler"
                placeholder="Enter open workstations/available capacity"
            />
            <p v-for="error of v$.workStationCapacity.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.agents_receive_health_insurance }}</p>
            <Dropdown
                v-model="v$.agentHealthInsur.$model"
                @change="changeHandler"
                :options="yesNoOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select agents receive health insurance"
            />
            <p v-for="error of v$.agentHealthInsur.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ staffManagementLabel.agent_turnover_percent }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.agentTurnOver.$model"
                    @input="changeHandler"
                    placeholder="Enter percentage of agent turnover"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.agentTurnOver.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.turnover_calculation }}</p>
            <Textarea
                class="input-text"
                v-model="v$.defAgentTurnOver.$model"
                name="defAgentTurnOver"
                @input="changeHandler"
                placeholder="Enter how you calculate agent turnover"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="defAgentTurnOverName"
                :logoUrl="defAgentTurnOverURL"
            />
            <p v-for="error of v$.defAgentTurnOver.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.culture }}</p>
            <Textarea
                class="input-text"
                v-model="v$.companyCulture.$model"
                name="companyCulture"
                @input="changeHandler"
                placeholder="Enter your company's culture"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="companyCultureName"
                :logoUrl="companyCultureURL"
            />
            <p v-for="error of v$.companyCulture.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.agent_retention }}</p>
            <Textarea
                class="input-text"
                v-model="v$.employeeRetent.$model"
                name="employeeRetent"
                @input="changeHandler"
                placeholder="Enter programs designed"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="employeeRetentName"
                :logoUrl="employeeRetentURL"
            />
            <p v-for="error of v$.employeeRetent.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ staffManagementLabel.current_customers }}</p>
            <Textarea
                class="input-text"
                v-model="v$.fewClientNames.$model"
                name="fewClientNames"
                @input="changeHandler"
                placeholder="Enter few client names"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="fewClientNamesName"
                :logoUrl="fewClientNamesURL"
            />
            <p v-for="error of v$.fewClientNames.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';
import Tooltip from '../Tooltip.vue';

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};
const positiveDecimal = (value) => {
    if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    components: {
        FileUpload,
        Tooltip,
    },
    props: ['saveInd', 'StaffManagementValues'],
    data() {
        return {
            staffManagementLabel: answerBankLabel.staffManagement,
            agentHealthInsur: null,
            agentTurnOver: null,
            defAgentTurnOver: null,
            defAgentTurnOverURL: null,
            defAgentTurnOverName: null,
            employeeRetent: null,
            employeeRetentURL: null,
            employeeRetentName: null,
            fewClientNames: null,
            fewClientNamesURL: null,
            fewClientNamesName: null,
            companyCulture: null,
            companyCultureURL: null,
            companyCultureName: null,
            tentureLT: null,
            siteSqrt: null,
            agentWorkSqrt: null,
            workStationCapacity: null,
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            agentHealthInsur: { minLength: minLength(1) },
            agentTurnOver: { positiveDecimal },
            defAgentTurnOver: { $autoDirty: true },
            employeeRetent: { $autoDirty: true },
            fewClientNames: { $autoDirty: true },
            companyCulture: { $autoDirty: true },
            tentureLT: { $autoDirty: true },
            siteSqrt: { positiveInt },
            agentWorkSqrt: { positiveInt },
            workStationCapacity: { positiveInt },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        StaffManagementValues() {
            if (this.StaffManagementValues === null) {
                this.agentHealthInsur = null;
                this.agentTurnOver = null;
                this.defAgentTurnOver = null;
                this.employeeRetent = null;
                this.fewClientNames = null;
                this.companyCulture = null;
                this.tentureLT = null;
                this.siteSqrt = null;
                this.agentWorkSqrt = null;
                this.workStationCapacity = null;
                return;
            }
            try {
                this.StaffManagementValues?.forEach((element) => {
                    if (element.field_name === 'agents_receive_health_insurance') this.agentHealthInsur = element.json_value;
                    if (element.field_name === 'agent_turnover_percent') this.agentTurnOver = element.json_value;
                    if (element.field_name === 'turnover_calculation') {
                        this.defAgentTurnOverName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.defAgentTurnOver = element.json_value;
                        this.defAgentTurnOverURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'agent_retention') {
                        this.employeeRetentName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.employeeRetent = element.json_value;
                        this.employeeRetentURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'current_customers') {
                        this.fewClientNamesName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.fewClientNames = element.json_value;
                        this.fewClientNamesURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'culture') {
                        this.companyCultureName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.companyCulture = element.json_value;
                        this.companyCultureURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'leadership_team_tenure') this.tentureLT = element.json_value;
                    if (element.field_name === 'site_square_feet') this.siteSqrt = element.json_value;
                    if (element.field_name === 'site_square_feet_per_agent') this.agentWorkSqrt = element.json_value;
                    if (element.field_name === 'workstation_unused_capacity') this.workStationCapacity = element.json_value;
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.StaffManagementValues?.forEach((element) => {
                if (element.field_name === 'agents_receive_health_insurance') element.json_value = this.agentHealthInsur;
                if (element.field_name === 'agent_turnover_percent') element.json_value = parseFloat(this.agentTurnOver);
                if (element.field_name === 'turnover_calculation') element.json_value = this.stringCheck(this.defAgentTurnOver);
                if (element.field_name === 'agent_retention') element.json_value = this.stringCheck(this.employeeRetent);
                if (element.field_name === 'current_customers') element.json_value = this.stringCheck(this.fewClientNames);
                if (element.field_name === 'culture') element.json_value = this.stringCheck(this.companyCulture);
                if (element.field_name === 'leadership_team_tenure') element.json_value = this.stringCheck(this.tentureLT);
                if (element.field_name === 'site_square_feet') element.json_value = parseInt(this.siteSqrt);
                if (element.field_name === 'site_square_feet_per_agent') element.json_value = parseInt(this.agentWorkSqrt);
                if (element.field_name === 'workstation_unused_capacity') element.json_value = parseInt(this.workStationCapacity);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.StaffManagementValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
