<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.internal_crm_systems }}</p>
            <MultiSelect
                v-model="v$.internalSys.$model"
                @change="changeHandler"
                :options="crmSysOptions"
                optionLabel="name"
                placeholder="Select internal CRM systems"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.internalSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.client_crm_systems }}</p>
            <MultiSelect
                v-model="v$.clientSys.$model"
                @change="changeHandler"
                :options="crmSysOptions"
                optionLabel="name"
                placeholder="Select client CRM systems"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.clientSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.datasec_certifications }}</p>
            <MultiSelect
                v-model="v$.dataSecCerts.$model"
                @change="changeHandler"
                :options="dataSecCertsOptions"
                optionLabel="name"
                placeholder="Select data security certifications"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.dataSecCerts.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.industry_other_certifications }}</p>
            <Textarea
                class="input-text"
                v-model="v$.OtherCerts.$model"
                name="OtherCerts"
                @input="changeHandler"
                placeholder="Enter other certificates"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="OtherCertsName"
                :logoUrl="OtherCertsURL"
            />
            <p v-for="error of v$.OtherCerts.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.other_crm_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.OtherInternalSys.$model"
                name="OtherInternalSys"
                @input="changeHandler"
                placeholder="Enter other internal CRM systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="OtherInternalSysName"
                :logoUrl="OtherInternalSysURL"
            />
            <p v-for="error of v$.OtherInternalSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemAndCertLabel.client_crm_systems_other }}</p>
            <Textarea
                class="input-text"
                v-model="v$.OtherClientSys.$model"
                name="OtherClientSys"
                @input="changeHandler"
                placeholder="Enter other client CRM systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="OtherClientSysName"
                :logoUrl="OtherClientSysURL"
            />
            <p v-for="error of v$.OtherClientSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'SystemAndCertValues'],
    data() {
        return {
            SystemAndCertLabel: answerBankLabel.SystemAndCert,
            dataSecCerts: null,
            OtherCerts: null,
            OtherCertsURL: null,
            OtherCertsName: null,
            internalSys: null,
            OtherInternalSys: null,
            OtherInternalSysURL: null,
            OtherInternalSysName: null,
            clientSys: null,
            OtherClientSys: null,
            OtherClientSysURL: null,
            OtherClientSysName: null,
            partnerId: null,
            dataSecCertsOptions: [
                { name: 'CCPA' },
                { name: 'DPA' },
                { name: 'PCI' },
                { name: 'Protected A' },
                { name: 'Protected B' },
                { name: 'Protected C' },
                { name: 'CPNI' },
                { name: 'GPDR' },
                { name: 'PII' },
                { name: 'SOCII' }
            ],
            crmSysOptions: [
                { name: 'Agile CRM' },
                { name: 'Creatio' },
                { name: 'CRMNEXT' },
                { name: 'CustomerWise' },
                { name: 'eGain' },
                { name: 'Freshdesk' },
                { name: 'Gorgias' },
                { name: 'Happy Fox' },
                { name: 'Help Scout' },
                { name: 'HelpCrunch' },
                { name: 'HubSpot' },
                { name: 'Intercom' },
                { name: 'Jira' },
                { name: 'Kustomer' },
                { name: 'LiveAgent' },
                { name: 'Microsoft' },
                { name: 'Oracle' },
                { name: 'Pegasystems' },
                { name: 'Pipedrive' },
                { name: 'Pipefy' },
                { name: 'Salesforce' },
                { name: 'SAP' },
                { name: 'ServiceNow' },
                { name: 'Spiceworks' },
                { name: 'SugarCRM' },
                { name: 'Supportbench' },
                { name: 'Verint Systems' },
                { name: 'Zendesk' },
                { name: 'Zoho' },
                { name: 'Other' },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            dataSecCerts: { minLength: minLength(1) },
            OtherCerts: { $autoDirty: true },
            internalSys: { minLength: minLength(1) },
            OtherInternalSys: { $autoDirty: true },
            clientSys: { minLength: minLength(1) },
            OtherClientSys: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        SystemAndCertValues() {
            if (this.SystemAndCertValues === null) {
                this.dataSecCerts = null;
                this.OtherCerts = null;
                this.internalSys = null;
                this.OtherInternalSys = null;
                this.clientSys = null;
                this.OtherClientSys = null;
                return;
            }
            try {
                this.SystemAndCertValues?.forEach((element) => {
                    if (element.field_name === 'datasec_certifications')
                        this.dataSecCerts = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'industry_other_certifications') {
                        this.OtherCertsName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.OtherCerts = element.json_value;
                        this.OtherCertsURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_crm_systems')
                        this.internalSys = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'other_crm_systems') {
                        this.OtherInternalSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.OtherInternalSys = element.json_value;
                        this.OtherInternalSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_crm_systems')
                        this.clientSys = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'client_crm_systems_other') {
                        this.OtherClientSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.OtherClientSys = element.json_value;
                        this.OtherClientSysURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.SystemAndCertValues?.forEach((element) => {
                if (element.field_name === 'datasec_certifications')
                    element.json_value = this.dataSecCerts?.map((ele) => ele.name);
                if (element.field_name === 'industry_other_certifications')
                    element.json_value = this.stringCheck(this.OtherCerts);
                if (element.field_name === 'internal_crm_systems') element.json_value = this.internalSys?.map((ele) => ele.name);
                if (element.field_name === 'other_crm_systems') element.json_value = this.stringCheck(this.OtherInternalSys);
                if (element.field_name === 'client_crm_systems') element.json_value = this.clientSys?.map((ele) => ele.name);
                if (element.field_name === 'client_crm_systems_other') element.json_value = this.stringCheck(this.OtherClientSys);
            });
            this.$emit('submit', this.v$.$silentErrors.length, this.SystemAndCertValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
