<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ PricingModelsLabel.average_hourly_rate_non_tech }}
            </p>
            <InputText
                class="input-text"
                v-model="v$.avgRateNonTech.$model"
                @input="changeHandler"
                placeholder="Enter average hourly rate for non-technical services"
            />
            <p v-for="error of v$.avgRateNonTech.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ PricingModelsLabel.average_hourly_rate_tech_t2 }}
            </p>
            <InputText
                class="input-text"
                v-model="v$.avgRateTech1.$model"
                @input="changeHandler"
                placeholder="Enter average hourly rate for tech tier 1 services"
            />
            <p v-for="error of v$.avgRateTech1.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ PricingModelsLabel.average_hourly_rate_tech_t3 }}</p>
            <InputText
                class="input-text"
                v-model="v$.avgRateTech2.$model"
                @input="changeHandler"
                placeholder="Enter average hourly rate for tech tier 2 services"
            />
            <p v-for="error of v$.avgRateTech2.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ PricingModelsLabel.average_hourly_rate_tech_t4 }}
            </p>
            <InputText
                class="input-text"
                v-model="v$.avgRateTech3.$model"
                @input="changeHandler"
                placeholder="Enter average hourly rate for tech tier 3 services"
            />
            <p v-for="error of v$.avgRateTech3.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ PricingModelsLabel.overtime_multiplier }}
                <Tooltip description="That is, do you charge 1.5x for work performed outside of regular business hours?  2x?" />
            </p>
            <InputText
                class="input-text"
                v-model="v$.OTMultiplier.$model"
                @input="changeHandler"
                placeholder="Enter overtime multiplier"
            />
            <p v-for="error of v$.OTMultiplier.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ PricingModelsLabel.observed_holiday_multiplier }}
                <Tooltip description="That is, do you charge 1.5x for work performed on recognized holidays?  2x?" />
            </p>
            <InputText
                class="input-text"
                v-model="v$.holidayMutiplier.$model"
                @input="changeHandler"
                placeholder="Enter holiday multiplier"
            />
            <p v-for="error of v$.holidayMutiplier.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ PricingModelsLabel.training_discount_percent }}</p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.trainDisPCT.$model"
                    @input="changeHandler"
                    placeholder="Enter training discount percentage"
                />
                <span class="p-inputgroup-addon"> % </span>
            </div>
            <p v-for="error of v$.trainDisPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ PricingModelsLabel.pricing_models_available }}</p>
            <MultiSelect
                v-model="v$.pricingModels.$model"
                @change="changeHandler"
                :options="pricingModelsOptions"
                optionLabel="name"
                placeholder="Select pricing models"
                :filter="true"
                display="chip"
            />
            <p v-for="error of v$.pricingModels.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Tooltip from '../Tooltip.vue';

const positiveDecimal = (value) => {
    if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};
export default {
    components: {
        Tooltip,
    },
    props: ['saveInd', 'PricingModelsValues'],
    data() {
        return {
            PricingModelsLabel: answerBankLabel.PricingModelsAndGuide,
            pricingModels: null,
            avgRateNonTech: null,
            avgRateTech1: null,
            avgRateTech2: null,
            avgRateTech3: null,
            OTMultiplier: null,
            holidayMutiplier: null,
            trainDisPCT: null,
            pricingModelsOptions: [
                { name: 'FTE' },
                { name: 'Worked Hour' },
                { name: 'Production Hour' },
                { name: 'Per Interaction' },
                { name: 'Per Minute' },
                { name: 'Shared Team' },
                { name: 'Other' },
            ],
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            pricingModels: { minLength: minLength(1) },
            avgRateNonTech: { positiveDecimal },
            avgRateTech1: { positiveDecimal },
            avgRateTech2: { positiveDecimal },
            avgRateTech3: { positiveDecimal },
            OTMultiplier: { positiveDecimal },
            holidayMutiplier: { positiveDecimal },
            trainDisPCT: { positiveDecimal },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        PricingModelsValues() {
            if (this.PricingModelsValues === null) {
                this.pricingModels = null;
                this.avgRateNonTech = null;
                this.avgRateTech1 = null;
                this.avgRateTech2 = null;
                this.avgRateTech3 = null;
                this.OTMultiplier = null;
                this.holidayMutiplier = null;
                this.trainDisPCT = null;
                return;
            }
            try {
                this.PricingModelsValues?.forEach((element) => {
                    if (element.field_name === 'pricing_models_available')
                        this.pricingModels = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'average_hourly_rate_non_tech') this.avgRateNonTech = element.json_value;
                    if (element.field_name === 'average_hourly_rate_tech_t1') this.avgRateTech1 = element.json_value;
                    if (element.field_name === 'average_hourly_rate_tech_t2') this.avgRateTech2 = element.json_value;
                    if (element.field_name === 'average_hourly_rate_tech_t3') this.avgRateTech3 = element.json_value;
                    if (element.field_name === 'overtime_multiplier') this.OTMultiplier = element.json_value;
                    if (element.field_name === 'observed_holiday_multiplier') this.holidayMutiplier = element.json_value;
                    if (element.field_name === 'training_discount_percent') this.trainDisPCT = element.json_value;
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        validationCall() {
            this.PricingModelsValues?.forEach((element) => {
                if (element.field_name === 'pricing_models_available')
                    element.json_value = this.pricingModels?.map((ele) => ele.name);
                if (element.field_name === 'average_hourly_rate_non_tech') element.json_value = parseFloat(this.avgRateNonTech);
                if (element.field_name === 'average_hourly_rate_tech_t1') element.json_value = parseFloat(this.avgRateTech1);
                if (element.field_name === 'average_hourly_rate_tech_t2') element.json_value = parseFloat(this.avgRateTech2);
                if (element.field_name === 'average_hourly_rate_tech_t3') element.json_value = parseFloat(this.avgRateTech3);
                if (element.field_name === 'overtime_multiplier') element.json_value = parseFloat(this.OTMultiplier);
                if (element.field_name === 'observed_holiday_multiplier') element.json_value = parseFloat(this.holidayMutiplier);
                if (element.field_name === 'training_discount_percent') element.json_value = parseFloat(this.trainDisPCT);
            });
            this.$emit('submit', this.v$.$silentErrors.length, this.PricingModelsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.p-inputgroup {
    max-width: 31rem;
}
.p-inputgroup-addon {
    background: rgb(228, 228, 228);
    font-size: large;
}
</style>
