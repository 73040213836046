<template>
    <div class="p-grid p-fluid">

        <Divider align="left">
            <span class="innerHeader">{{ SocialRepLabel.cert_diverse_header }}</span>
        </Divider>

        <div class="p-col-12 p-md-12">
            <span class="innerSubHeader" >
                {{ SocialRepLabel.certification_diverse_explanation }}
            </span>
        </div>

        <!-- WOMAN OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0rem; ">
            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.cert_diverse_woman_owned }}
                    </p>
                    <Dropdown v-model="v$.cert_diverse_woman_owned.$model" @change="changeHandler" :options="yesNoInProgressOptions" 
                        optionLabel="name" optionValue="value" name="cert_diverse_woman_owned"
                        placeholder="Select a value..." />
                </div>
            </div>
            
            <div class="p-col-12 p-md-4" :class="{ hidden: v$.cert_diverse_woman_owned.$model != 'Yes' }">
                <p class="field-title">
                    {{ SocialRepLabel.cert_diverse_how_meet }}
                </p>
                <InputText class="input-text" v-model="v$.cert_diverse_woman_owned_cert_req.$model"
                    name="cert_diverse_woman_owned_cert_req" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
                <p v-for="error of v$.cert_diverse_woman_owned_cert_req.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-5" :class="{ hidden: v$.cert_diverse_woman_owned.$model != 'Yes' }">
                <p class="field-title">{{
                    SocialRepLabel.cert_diverse_certifying_body
                }}</p>
                <InputText class="input-text" v-model="v$.cert_diverse_woman_owned_cert_body.$model"
                    name="cert_diverse_woman_owned_cert_body" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
                <p v-for="error of v$.cert_diverse_woman_owned_cert_body.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
                <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                    fieldName="cert_diverse_woman_owned_cert_body" :logoUrl="url.cert_diverse_woman_owned_cert_body" />
            </div> 
        </div>

        <!-- MINORITY OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0rem; ">
            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.cert_diverse_minority_owned }}
                    </p>
                    <Dropdown v-model="v$.cert_diverse_minority_owned.$model" name="cert_diverse_minority_owned"
                        @change="changeHandler" :options="yesNoInProgressOptions" optionLabel="name" optionValue="value"
                        placeholder="Select a value..." />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.cert_diverse_minority_owned.$model != 'Yes' }">
                <p class="field-title">
                    {{ SocialRepLabel.cert_diverse_how_meet }}
                </p>
                <InputText class="input-text" v-model="v$.cert_diverse_minority_owned_cert_req.$model"
                    @input="changeHandler" name="cert_diverse_minority_owned_cert_req"
                    :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
                <p v-for="error of v$.cert_diverse_minority_owned_cert_req.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-5" :class="{ hidden: v$.cert_diverse_minority_owned.$model != 'Yes' }">
                <p class="field-title">{{
                    SocialRepLabel.cert_diverse_certifying_body
                }}</p>
                <InputText class="input-text" v-model="v$.cert_diverse_minority_owned_cert_body.$model"
                    name="cert_diverse_minority_owned_cert_body" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
                <p v-for="error of v$.cert_diverse_minority_owned_cert_body.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
                <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                    fieldName="cert_diverse_minority_owned_cert_body" :logoUrl="url.cert_diverse_minority_owned_cert_body" />
            </div>
        </div> 

        <!-- DISABLED OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0rem; ">

            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.cert_diverse_disabled_owned }}
                    </p>
                    <Dropdown v-model="v$.cert_diverse_disabled_owned.$model" name="cert_diverse_disabled_owned"
                        @change="changeHandler" :options="yesNoInProgressOptions" optionLabel="name" optionValue="value"
                        placeholder="Select a value..." />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.cert_diverse_disabled_owned.$model != 'Yes' }">
                <p class="field-title">
                    {{ SocialRepLabel.cert_diverse_how_meet }}
                </p>
                <InputText class="input-text" v-model="v$.cert_diverse_disabled_owned_cert_req.$model"
                    name="cert_diverse_disabled_owned_cert_req" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
                <p v-for="error of v$.cert_diverse_disabled_owned_cert_req.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-5" :class="{ hidden: v$.cert_diverse_disabled_owned.$model != 'Yes' }">
                <p class="field-title">{{
                    SocialRepLabel.cert_diverse_certifying_body
                }}</p>
                <InputText class="input-text" v-model="v$.cert_diverse_disabled_owned_cert_body.$model"
                    name="cert_diverse_disabled_owned_cert_body" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
                <p v-for="error of v$.cert_diverse_disabled_owned_cert_body.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
                <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                    fieldName="cert_diverse_disabled_owned_cert_body" :logoUrl="url.cert_diverse_disabled_owned_cert_body" />
            </div>
        </div> 

        <!-- VETERAN OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0rem; ">
            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.cert_diverse_veteran_owned }}
                    </p>
                    <Dropdown v-model="v$.cert_diverse_veteran_owned.$model" name="cert_diverse_veteran_owned"
                        @change="changeHandler" :options="yesNoInProgressOptions" optionLabel="name" optionValue="value"
                        placeholder="Select a value..." />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.cert_diverse_veteran_owned.$model != 'Yes' }">
                <p class="field-title">
                    {{ SocialRepLabel.cert_diverse_how_meet }}
                </p>
                <InputText class="input-text" v-model="v$.cert_diverse_veteran_owned_cert_req.$model"
                    name="cert_diverse_veteran_owned_cert_req" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
                <p v-for="error of v$.cert_diverse_veteran_owned_cert_req.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-5" :class="{ hidden: v$.cert_diverse_veteran_owned.$model != 'Yes' }">
                <p class="field-title">{{
                    SocialRepLabel.cert_diverse_certifying_body
                }}</p>
                <InputText class="input-text" v-model="v$.cert_diverse_veteran_owned_cert_body.$model"
                    name="cert_diverse_veteran_owned_cert_body" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
                <p v-for="error of v$.cert_diverse_veteran_owned_cert_body.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
                <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                    fieldName="cert_diverse_veteran_owned_cert_body" :logoUrl="url.cert_diverse_veteran_owned_cert_body" />
            </div>
        </div>

        <!-- "OTHER" OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0rem; "> 

            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.cert_diverse_other_owned }}
                    </p>
                    <Dropdown v-model="v$.cert_diverse_other_owned.$model" name="cert_diverse_other_owned"
                        @change="changeHandler" :options="yesNoInProgressOptions" optionLabel="name" optionValue="value"
                        placeholder="Select a value..." />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.cert_diverse_other_owned.$model != 'Yes' }">
                <p class="field-title">
                    {{ SocialRepLabel.cert_diverse_how_meet }}
                </p>
                <InputText class="input-text" v-model="v$.cert_diverse_other_owned_cert_req.$model"
                    name="cert_diverse_other_owned_cert_req" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
                <p v-for="error of v$.cert_diverse_other_owned_cert_req.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>
            <div class="p-col-12 p-md-5" :class="{ hidden: v$.cert_diverse_other_owned.$model != 'Yes' }">
                <p class="field-title">{{
                    SocialRepLabel.cert_diverse_certifying_body
                }}</p>
                <InputText class="input-text" v-model="v$.cert_diverse_other_owned_cert_body.$model"
                    name="cert_diverse_other_owned_cert_body" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
                <p v-for="error of v$.cert_diverse_other_owned_cert_body.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
                <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                    fieldName="cert_diverse_other_owned_cert_body" :logoUrl="url.cert_diverse_other_owned_cert_body" />
            </div> 

        </div>

        <div class="p-col-12 p-md-12">
            <p class="innerSubHeader" style="max-width: 100%;">
                {{ SocialRepLabel.noncertification_diverse_explanation }}
            </p>
        </div>

        <!-- FAITH OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0px; margin-bottom: 20px;">
            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.noncert_diverse_faith_owned }}
                    </p>
                    <Dropdown v-model="v$.noncert_diverse_faith_owned.$model" name="noncert_diverse_faith_owned"
                        @change="changeHandler" :options="yesNoOptions" optionLabel="name" optionValue="value"
                        placeholder="Select Yes or No" />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.noncert_diverse_faith_owned.$model != true }">
                <p class="field-title">
                    {{ SocialRepLabel.noncert_diverse_faith_owned_summary }}
                </p>
                <InputText class="input-text" v-model="v$.noncert_diverse_faith_owned_summary.$model"
                    name="noncert_diverse_faith_owned_summary" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_detail_optional" />
                <p v-for="error of v$.noncert_diverse_faith_owned_summary.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>            
        </div>

        <!-- "OTHER" OWNED -->
        <div class="p-col-12 p-md-12 p-grid p-fluid certDiverseSubType" style="margin-left: 0px; margin-bottom: 20px;">
            <div class="p-col-12 p-md-3">
                <div style="padding-left: 2rem;">
                    <p class="field-title">
                        {{ SocialRepLabel.noncert_diverse_other }}
                    </p>
                    <Dropdown v-model="v$.noncert_diverse_other.$model" name="noncert_diverse_other"
                        @change="changeHandler" :options="yesNoOptions" optionLabel="name" optionValue="value"
                        placeholder="Select Yes or No" />
                </div>
            </div>

            <div class="p-col-12 p-md-4" :class="{ hidden: v$.noncert_diverse_other.$model != true }">
                <p class="field-title">
                    {{ SocialRepLabel.noncert_diverse_other_summary }}
                </p>
                <InputText class="input-text" v-model="v$.noncert_diverse_other_summary.$model"
                    name="noncert_diverse_other_owned_summary" @input="changeHandler"
                    :placeholder="SocialRepLabel.socialrep_explanation_detail_optional" />
                <p v-for="error of v$.noncert_diverse_other_summary.$errors" :key="error.$uid"
                    class="error-msg">
                    {{ 'Please enter a value' }}
                </p>
            </div>            
        </div>





        <!-- ==================================================================================================== -->

        <Divider align="left">
            <span class="innerHeader">{{ SocialRepLabel.econ_disadvantaged_header }}</span>
        </Divider>

        <div class="p-col-12 p-md-7">
            <p class="field-title explanatory-title" v-html="SocialRepLabel.econ_disadvantaged_explanation" />
        </div>
        <div class="p-col-12 p-md-5"></div>

        <div class="p-col-12 p-md-3">
            <div style="padding-left: 2rem;">
                <p class="field-title">
                    {{ SocialRepLabel.econ_disadvantaged_program_yes }}
                </p>
                <Dropdown v-model="v$.econ_disadvantaged_program.$model" name="econ_disadvantaged_program" @change="changeHandler" :options="yesNoOptions"
                    optionLabel="name" optionValue="value" placeholder="Select Yes or No" />
            </div>
        </div>
        <div class="p-col-12 p-md-4" :class="{ hidden: !v$.econ_disadvantaged_program.$model }">
            <p class="field-title">
                {{ SocialRepLabel.econ_disadvantaged_locations }}
            </p>
            <InputText class="input-text" v-model="v$.econ_disadvantaged_locations.$model" name="econ_disadvantaged_locations" @input="changeHandler"
                :placeholder="SocialRepLabel.socialrep_explanation_placeholder" />
            <p v-for="error of v$.econ_disadvantaged_locations.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter a value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-5" :class="{ hidden: !v$.econ_disadvantaged_program.$model }">
            <p class="field-title">{{
                SocialRepLabel.econ_disadvantaged_quals_and_certs
            }}</p>
            <InputText class="input-text" v-model="v$.econ_disadvantaged_quals_and_certs.$model" name="econ_disadvantaged_quals_and_certs" @input="changeHandler"
                :placeholder="SocialRepLabel.socialrep_cert_body_placeholder" />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                fieldName="econ_disadvantaged_quals_and_certs" :logoUrl="url.econ_disadvantaged_quals_and_certs" />
        </div>





        <!-- ==================================================================================================== -->

        <Divider align="left">
            <span class="innerHeader">{{ SocialRepLabel.employ_programs_header }}</span>
        </Divider>

        <div class="p-col-12 p-md-7">
            <p class="field-title explanatory-title" v-html="SocialRepLabel.employ_programs_explanation" />
        </div>
        <div class="p-col-12 p-md-5"></div>

        <div class="p-col-12 p-md-3">
            <div style="padding-left: 2rem;">
                <p class="field-title">
                    {{ SocialRepLabel.employ_program_do_you_have }}
                </p>
                <Dropdown v-model="v$.employ_program.$model" name="employ_program" @change="changeHandler" :options="yesNoOptions"
                    optionLabel="name" optionValue="value" placeholder="Select Yes or No" />
            </div>
        </div>

        <div class="p-col-12 p-md-4" :class="{ hidden: !v$.employ_program.$model }">
             
                <p class="field-title">
                    {{ SocialRepLabel.employ_programs_which_populations }}
                </p>
                <MultiSelect v-model="v$.employ_programs_which_populations.$model" name="employ_programs_which_populations" :options="impactSourcingOptions" @change="changeHandler" 
                    optionLabel="name" optionValue="value" :placeholder="SocialRepLabel.employ_programs_placeholder" />
             
        </div>

        <div class="p-col-12 p-md-5"  :class="{ hidden: !v$.employ_program.$model }">
            <p class="field-title">
                {{ SocialRepLabel.employ_programs_which_populations_details }}
            </p>
            <InputText class="input-text" v-model="v$.employ_programs_which_populations_details.$model" name="employ_programs_which_populations_details" @input="changeHandler"
                :placeholder="SocialRepLabel.employ_programs_details_placeholder" />
            <p v-for="error of v$.employ_programs_which_populations_details.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter a value' }}
            </p>
        </div>


        <!-- ==================================================================================================== -->

        <Divider align="left" style="margin-top: 4rem;">
            <span class="innerHeader">{{ SocialRepLabel.social_resp_header }}</span>
        </Divider>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.culture_give_back }}</p>
            <Textarea class="input-text" v-model="v$.culture_give_back.$model" name="culture_give_back" @input="changeHandler"
                placeholder="Enter how you give back to the communities in which you operate" rows="5" cols="30"
                 />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                fieldName="culture_give_back" :logoUrl="url.culture_give_back" />
            <p v-for="error of v$.culture_give_back.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.charitable_organizations }}</p>
            <Textarea class="input-text" v-model="v$.charitable_organizations.$model" name="charitable_organizations" @input="changeHandler"
                placeholder="Enter charitable organizations or philanthropic organizations" rows="5" cols="30"
                 />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId" fieldName="charitable_organizations"
                :logoUrl="url.charitable_organizations" />
            <p v-for="error of v$.charitable_organizations.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.company_values }}</p>
            <Textarea class="input-text" v-model="v$.company_values.$model" name="company_values" @input="changeHandler"
                placeholder="Enter your company values" rows="5" cols="30"  />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                fieldName="company_values" :logoUrl="url.company_values" />
            <p v-for="error of v$.company_values.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SocialRepLabel.mission_vision }}</p>
            <Textarea class="input-text" v-model="v$.mission_vision.$model" name="mission_vision" @input="changeHandler"
                placeholder="Enter your mission/vision" rows="5" cols="30"  />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                fieldName="mission_vision" :logoUrl="url.mission_vision" />
            <p v-for="error of v$.mission_vision.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ SocialRepLabel.has_csr_plan }}
            </p>
            <Dropdown v-model="v$.has_csr_plan.$model" @change="changeHandler" :options="yesNoOptions"
                optionLabel="name" optionValue="value"
                placeholder="Select documented Corporate Social Responsibility Plan" />
            <p v-for="error of v$.has_csr_plan.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6" :class="{ hidden: !has_csr_plan }">
            <p class="field-title">{{ SocialRepLabel.csr_plan_attached }}</p>
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="false" :id="partnerId"
                fieldName="csr_plan_attached" :logoUrl="url.csr_plan_attached" />
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';
// import Tooltip from '../Tooltip.vue';


const socialRepKeys = [
    'has_csr_plan',
    'culture_give_back',
    'charitable_organizations',
    'company_values',
    'mission_vision',
    'cert_diverse_woman_owned',
    'cert_diverse_woman_owned_cert_req',
    'cert_diverse_woman_owned_cert_body',
    'cert_diverse_minority_owned',
    'cert_diverse_minority_owned_cert_req',
    'cert_diverse_minority_owned_cert_body',
    'cert_diverse_disabled_owned',
    'cert_diverse_disabled_owned_cert_req',
    'cert_diverse_disabled_owned_cert_body',
    'cert_diverse_veteran_owned',
    'cert_diverse_veteran_owned_cert_req',
    'cert_diverse_veteran_owned_cert_body',
    'cert_diverse_other_owned',
    'cert_diverse_other_owned_cert_req',
    'cert_diverse_other_owned_cert_body',
    'noncert_diverse_faith_owned',
    'noncert_diverse_faith_owned_summary',
    'noncert_diverse_other',
    'noncert_diverse_other_summary',
    'econ_disadvantaged_program',
    'econ_disadvantaged_locations',
    'econ_disadvantaged_quals_and_certs',
    'employ_program',
    'employ_programs_which_populations',
    'employ_programs_which_populations_details',
]


export default {
    components: {
        FileUpload,
        // Tooltip,
    },
    props: ['saveInd', 'SocialRepValues'],
    data() {
        return {
            SocialRepLabel: answerBankLabel.SocialRep,
            url: {
                csr_plan_attached: null,
                culture_give_back: null,
                charitable_organizations: null,
                company_values: null,
                mission_vision: null,
                cert_diverse_woman_owned_cert_body: null,
                cert_diverse_minority_owned_cert_body: null,
                cert_diverse_disabled_owned_cert_body: null,
                cert_diverse_veteran_owned_cert_body: null,
                cert_diverse_other_owned_cert_body: null,
                econ_disadvantaged_quals_and_certs: null,
            },
            has_csr_plan: null,
            culture_give_back: null,
            charitable_organizations: null,
            company_values: null,
            mission_vision: null,
            partnerId: null,
            //----------------
            cert_diverse_woman_owned: null,
            cert_diverse_woman_owned_cert_req: null,
            cert_diverse_woman_owned_cert_body: null,
            cert_diverse_minority_owned: null,
            cert_diverse_minority_owned_cert_req: null,
            cert_diverse_minority_owned_cert_body: null,
            cert_diverse_disabled_owned: null,
            cert_diverse_disabled_owned_cert_req: null,
            cert_diverse_disabled_owned_cert_body: null,
            cert_diverse_veteran_owned: null,
            cert_diverse_veteran_owned_cert_req: null,
            cert_diverse_veteran_owned_cert_body: null,
            cert_diverse_other_owned: null,
            cert_diverse_other_owned_cert_req: null,
            cert_diverse_other_owned_cert_body: null,

            noncert_diverse_faith_owned: null,
            noncert_diverse_faith_owned_summary: null,
            noncert_diverse_other: null,
            noncert_diverse_other_summary: null,

            econ_disadvantaged_program: null,
            econ_disadvantaged_locations: null,
            econ_disadvantaged_quals_and_certs: null,

            employ_program: null,
            employ_programs_which_populations: null,
            employ_programs_which_populations_details: null,

            impactSourcings: null,


            //----------------


            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
            yesNoInProgressOptions: [
                { name: 'Yes', value: 'Yes' },
                { name: 'No', value: 'No' },
                { name: 'In Progress', value: 'In Progress' },
            ],
            impactSourcingOptions: [
                { name: 'Hearing-impaired', value: 'Hearing-impaired' },
                { name: 'Visually-impaired', value: 'Visually-impaired' },
                { name: 'Neurodivergent', value: 'Neurodivergent' },
                { name: 'Second-chance', value: 'Second-chance' },
                { name: 'Formerly human-trafficked', value: 'Formerly human-trafficked' },
                { name: 'Refugee', value: 'Refugee' },
                { name: 'Veteran', value: 'Veteran' },
                { name: 'Other (specify)', value: 'Other (specify)' },

            ], 

        };
    },
    setup() {
        return { 
            v$: useVuelidate()        
        };

    },
    validations() {
        return {
            has_csr_plan: { minLength: minLength(1) },
            culture_give_back: { $autoDirty: true },
            charitable_organizations: { $autoDirty: true },
            company_values: { $autoDirty: true },
            mission_vision: { $autoDirty: true },
            //----------------
            cert_diverse_woman_owned: { $autoDirty: true },
            cert_diverse_woman_owned_cert_req: { $autoDirty: true },
            cert_diverse_woman_owned_cert_body: { $autoDirty: true },
            cert_diverse_minority_owned: { $autoDirty: true },
            cert_diverse_minority_owned_cert_req: { $autoDirty: true },
            cert_diverse_minority_owned_cert_body: { $autoDirty: true },
            cert_diverse_disabled_owned: { $autoDirty: true },
            cert_diverse_disabled_owned_cert_req: { $autoDirty: true },
            cert_diverse_disabled_owned_cert_body: { $autoDirty: true },
            cert_diverse_veteran_owned: { $autoDirty: true },
            cert_diverse_veteran_owned_cert_req: { $autoDirty: true },
            cert_diverse_veteran_owned_cert_body: { $autoDirty: true },
            cert_diverse_other_owned: { $autoDirty: true },
            cert_diverse_other_owned_cert_req: { $autoDirty: true },
            cert_diverse_other_owned_cert_body: { $autoDirty: true },
            
            noncert_diverse_faith_owned: { $autoDirty: true },
            noncert_diverse_faith_owned_summary: { $autoDirty: true },

            noncert_diverse_other: { $autoDirty: true },
            noncert_diverse_other_summary: { $autoDirty: true },

            econ_disadvantaged_program: { $autoDirty: true },
            econ_disadvantaged_locations: { $autoDirty: true },
            econ_disadvantaged_quals_and_certs: { $autoDirty: true },

            employ_program: { $autoDirty: true },
            employ_programs_which_populations: { $autoDirty: true },
            employ_programs_which_populations_details: { $autoDirty: true },
            //----------------
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        SocialRepValues() {
            //console.log('SocialRepValues', this.SocialRepValues);
            if (this.SocialRepValues === null) {
                socialRepKeys.forEach(key => {
                    this[key] = null
                })
                return;
            }
            this.partnerId = localStorage.getItem('accountId');
            try {
                this.SocialRepValues?.forEach((element) => {
                    const attachmentUrl = element?.other?.attachments[0] || null

                    this[element.field_name] = element.json_value

                    if(Object.prototype.hasOwnProperty.call(this.url, element.field_name))
                        this.url[element.field_name] = attachmentUrl; 
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        }
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.SocialRepValues?.forEach((element) => {
                if(typeof this[element.field_name] == 'string') {
                    element.json_value = this[element.field_name].trim()
                    return
                }
                
                element.json_value = this[element.field_name]
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.SocialRepValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}

::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}

.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}

.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}

::v-deep(.p-datatable) {
    .p-datatable-thead>tr>th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}

::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}

.table-wrap {
    margin-top: 5rem;
}

.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}

.innerHeader {
    font-size: 1.3rem;
    font-weight: 800;
    color: #868686;
    letter-spacing: 1px;
}

.innerSubHeader {    
    font-style: italic;
    font-weight: 300;
    font-size: 1.2rem;
    padding-left: 1rem;
    color: #777777;
    max-width: 100%;
}

.field-title {
    margin-left: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    max-width: 30rem;
}

.explanatory-title {
    font-style: italic;
    font-weight: 300;
    font-size: 1rem;
    padding-left: 1rem;
    color: #777777;
    max-width: 100%;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}

.disabledFont {
    color: #bbbbbb;
}

.hidden {
    visibility: hidden;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.certDiverseSubType {
    border-bottom: solid 1px #bbbbbb;
    margin-top: 0.2rem;
    padding-left: 25px;
}
</style>
