<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ResiliencyLabel.systems_redundancy_it_support }}</p>
            <InputText class="input-text" v-model="v$.ITCenterHr.$model" @input="changeHandler"
                placeholder="Enter number of hours is your IT center is staffed" />
            <p v-for="error of v$.ITCenterHr.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ResiliencyLabel.systems_redundancy_it_support_personnel }}
            </p>
            <InputText class="input-text" v-model="v$.systemsPeople.$model" @input="changeHandler"
                placeholder="Enter number of people you have in your systems department" />
            <p v-for="error of v$.systemsPeople.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ResiliencyLabel.has_bcp }}
            </p>
            <Dropdown v-model="v$.docBusinessContinuityPlan.$model" @change="changeHandler" :options="yesNoOptions"
                optionLabel="name" optionValue="value" placeholder="Select documented Business Continuity Plan" />
            <p v-for="error of v$.docBusinessContinuityPlan.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ResiliencyLabel.systems_redundancy_power }}</p>
            <Dropdown v-model="v$.backupGenerator.$model" @change="changeHandler" :options="yesNoOptions"
                optionLabel="name" optionValue="value" placeholder="Select backup generator at each site" />
            <p v-for="error of v$.backupGenerator.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ResiliencyLabel.systems_redundancy_connectivity }}</p>
            <Dropdown v-model="v$.redundantInternet.$model" @change="changeHandler" :options="yesNoOptions"
                optionLabel="name" optionValue="value" placeholder="Select redundant internet and telecom providers" />
            <p v-for="error of v$.redundantInternet.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ResiliencyLabel.bcp_document }}
            </p>
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="false" :id="partnerId"
                :fieldName="BCPdocumentName" :logoUrl="BCPdocumentURL" />
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ ResiliencyLabel.systems_redundancy }}</p>
            <Textarea class="input-text" v-model="v$.systemsRedundancy.$model" name="systemsRedundancy"
                @input="changeHandler" placeholder="Enter your systems redundancy and backup" rows="5" cols="30"
                 />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                :fieldName="systemsRedundancyName" :logoUrl="systemsRedundancyURL" />
            <p v-for="error of v$.systemsRedundancy.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ResiliencyLabel.bcp_description }}
            </p>
            <Textarea class="input-text" v-model="v$.descBusinessContinuityPlan.$model"
                name="descBusinessContinuityPlan" @input="changeHandler"
                placeholder="Enter your business continuity plan/disaster recovery plan" rows="5" cols="30"
                 />
            <FileUpload @saved="onSaved" :isImage="false" :isAttachment="true" :id="partnerId"
                :fieldName="descBusinessContinuityPlanName" :logoUrl="descBusinessContinuityPlanURL" />
            <p v-for="error of v$.descBusinessContinuityPlan.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ ResiliencyLabel.bcp_downtime }}
            </p>

            <InputText class="input-text" v-model="v$.experiencedDowntime.$model" @input="changeHandler"
                placeholder="Enter downtime days in the past 12 months" />


            <p v-for="error of v$.experiencedDowntime.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric/decimal value from 0 to 365' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength, minValue, maxValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

// const positiveIntOrZero = (value) => {
//     if (value && (value.length > 15 || value < 0 || !/^[0-9]\d*$/.test(value))) return false;
//     else return true;
// };

// const positiveDecimal = (value) => {
//     if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
//     else return true;
// };

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'ResiliencyValues'],
    data() {
        return {
            ResiliencyLabel: answerBankLabel.Resiliency,
            docBusinessContinuityPlan: null,
            descBusinessContinuityPlan: null,
            systemsRedundancy: null,
            descBusinessContinuityPlanURL: null,
            systemsRedundancyURL: null,
            descBusinessContinuityPlanName: null,
            systemsRedundancyName: null,
            backupGenerator: null,
            redundantInternet: null,
            ITCenterHr: null,
            systemsPeople: null,
            experiencedDowntime: null,
            BCPdocumentName: null,
            BCPdocumentURL: null,
            yesNoOptions: [
                { name: 'Yes', value: true },
                { name: 'No', value: false },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            docBusinessContinuityPlan: { minLength: minLength(1) },
            descBusinessContinuityPlan: { $autoDirty: true },
            systemsRedundancy: { $autoDirty: true },
            backupGenerator: { minLength: minLength(1) },
            redundantInternet: { minLength: minLength(1) },
            ITCenterHr: { $autoDirty: true },
            systemsPeople: { positiveInt },
            experiencedDowntime: { maxValue: maxValue(365), minValue: minValue(0) },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        ResiliencyValues() {
            if (this.ResiliencyValues === null) {
                this.docBusinessContinuityPlan = null;
                this.descBusinessContinuityPlan = null;
                this.systemsRedundancy = null;
                this.backupGenerator = null;
                this.redundantInternet = null;
                this.ITCenterHr = null;
                this.systemsPeople = null;
                this.experiencedDowntime = null;
                return;
            }
            try {
                this.ResiliencyValues?.forEach((element) => {
                    if (element.field_name === 'has_bcp') this.docBusinessContinuityPlan = element.json_value;
                    if (element.field_name === 'bcp_description') {
                        this.descBusinessContinuityPlanName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.descBusinessContinuityPlan = element.json_value;
                        this.descBusinessContinuityPlanURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'systems_redundancy') {
                        this.systemsRedundancyName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.systemsRedundancy = element.json_value;
                        this.systemsRedundancyURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'systems_redundancy_power') this.backupGenerator = element.json_value;
                    if (element.field_name === 'systems_redundancy_connectivity') this.redundantInternet = element.json_value;
                    if (element.field_name === 'systems_redundancy_it_support') this.ITCenterHr = element.json_value;
                    if (element.field_name === 'systems_redundancy_it_support_personnel') this.systemsPeople = element.json_value;
                    if (element.field_name === 'bcp_downtime') this.experiencedDowntime = element.json_value;
                    if (element.field_name === 'bcp_document') {
                        this.BCPdocumentName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.BCPdocumentURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;
            this.$emit('isValid', true);

            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.ResiliencyValues?.forEach((element) => {
                if (element.field_name === 'has_bcp') element.json_value = this.docBusinessContinuityPlan;
                if (element.field_name === 'bcp_description')
                    element.json_value = this.stringCheck(this.descBusinessContinuityPlan);
                if (element.field_name === 'systems_redundancy') element.json_value = this.stringCheck(this.systemsRedundancy);
                if (element.field_name === 'systems_redundancy_power') element.json_value = this.backupGenerator;
                if (element.field_name === 'systems_redundancy_connectivity') element.json_value = this.redundantInternet;
                if (element.field_name === 'systems_redundancy_it_support')
                    element.json_value = this.stringCheck(this.ITCenterHr);
                if (element.field_name === 'systems_redundancy_it_support_personnel')
                    element.json_value = parseInt(this.systemsPeople);
                if (element.field_name === 'bcp_downtime') element.json_value = parseInt(this.experiencedDowntime);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.ResiliencyValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}

::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}

.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}

.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}

::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}

::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}

::v-deep(.p-datatable) {
    .p-datatable-thead>tr>th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}

::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}

.table-wrap {
    margin-top: 5rem;
}

.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}

.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
