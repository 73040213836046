<template>
    <div class="toolbar-wrap">
        <div>
            <p class="field-title" style="margin-left: 0.5rem">
                Company headquarters address
                <Tooltip description="Provide the address of your global headquarters." />
            </p>
        </div>
        <Button label="Add New" class="mr-2 inline-block add-new-btn" @click="openNew" />
    </div>
    <div class="table-wrap">
        <DataTable
            ref="dt"
            :value="locationInfos"
            v-show="!!locationInfos?.length"
            dataKey="id"
            :paginator="true"
            :rows="5"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            @page="onPage($event)"
        >
            <Column :exportable="false" style="min-width: 2rem">
                <template #body="slotProps">
                    <Button
                        icon="pi pi-pencil"
                        class="p-button-rounded p-button-warning"
                        @click="editLocation(slotProps.data, slotProps.index)"
                    />
                    <Button
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-warning"
                        @click="deleteLocation(slotProps.data, slotProps.index)"
                    />
                </template>
            </Column>
            <Column field="name" header="Name" style="min-width: 9rem"></Column>
            <Column field="address1" header="Address 1" style="min-width: 9rem"></Column>
            <Column field="address2" header="Address 2" style="min-width: 9rem"></Column>
            <Column field="city" header="City" style="min-width: 9rem"></Column>
            <Column field="region" header="Region" style="min-width: 9rem"></Column>
            <Column field="postalCode" header="Postal code" style="min-width: 9rem"></Column>
            <Column field="country" header="Country" style="min-width: 9rem"></Column>
            <Column header="Primary" style="min-width: 9rem">
                <template #body="slotProps">
                    {{ slotProps.data?.primary ? 'Yes' : 'No' }}
                </template>
            </Column>
        </DataTable>

        <Dialog
            v-model:visible="locationDialog"
            :style="{ width: '600px' }"
            header="Location Details"
            :modal="true"
            class="p-fluid"
        >
            <div class="field">
                <label for="name">Name</label>
                <InputText id="name" v-model="locationInfo.name" required="true" />
            </div>

            <div class="field">
                <label for="address1">Address 1</label>
                <Textarea id="address1" v-model="locationInfo.address1" required="true" rows="3" cols="20" :autoResize="true" />
            </div>

            <div class="field">
                <label for="address2">Address 2</label>
                <Textarea id="address2" v-model="locationInfo.address2" required="true" rows="3" cols="20" :autoResize="true" />
            </div>

            <div class="field">
                <label for="city">City</label>
                <InputText id="city" v-model="locationInfo.city" required="true" />
            </div>

            <div class="field">
                <label for="region">Region</label>
                <InputText id="region" v-model="locationInfo.region" required="true" />
            </div>

            <div class="field">
                <label for="postalCode">Postal code</label>
                <InputText id="postalCode" v-model="locationInfo.postalCode" required="true" />
            </div>

            <div class="field">
                <label for="country">Country</label>
                <TreeSelect
                    v-model="locationInfo.countryName"
                    :options="countryListOptions"
                    placeholder="Select country"
                    selectionMode="single"
                    panelClass="p-tree-single"
                    />
            </div>

            <div class="field-checkbox">
                <Checkbox id="primary" v-model="locationInfo.primary" :binary="true" />
                <label for="primary" style="margin-left: 1rem; font-size: 16px">Primary</label>
            </div>

            <template #footer>
                <Button label="Cancel" class="p-button p-component p-button-outlined button" @click="hideDialog" />
                <Button v-if="editIndex === null" label="Add" class="p-button p-component button" @click="onLocationInfoSave" />
                <Button v-else label="Update" class="p-button p-component button" @click="onLocationInfoSave" />
            </template>
        </Dialog>

        <Dialog v-model:visible="deleteLocationDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 1.75rem; margin-right: 0.5rem" />
                <span v-if="locationInfo"
                    >Are you sure you want to delete <b>{{ locationInfo.name }}</b
                    >?</span
                >
            </div>
            <template #footer>
                <Button label="No" class="p-button p-component p-button-outlined button" @click="deleteLocationDialog = false" />
                <Button label="Yes" class="p-button p-component button" @click="onConfirmDeleteLocation" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import Tooltip from '../../components/Tooltip.vue';
import CountryService from '../../service/CountryService';
import { onMounted } from '@vue/runtime-core';
import { convertCountryDataToTreeSelect } from '../../service/common';

export default {
    name: 'LocationInfo',
    props: ['saveInd', 'locationInfoValues'],
    components: {
        Tooltip,
    },
    data() {
        return {
            locationInfos: [],
            locationDialog: false,
            deleteLocationDialog: false,
            locationInfo: {},
            submitted: false,
            editIndex: null,
            deleteLocationInfoIndex: null,
            intializeOnce: true,
            paginationData: { columnOffset: 0 },
            countryListOptions: []
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.countryService = new CountryService();
        onMounted(() => {
            this.countryService.getCountries().then((data) => {
                this.countryCodeMapping = {}
                this.countryListOptions = convertCountryDataToTreeSelect(data)
            });
        });        
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        locationInfoValues() {
            try {
                let locationInfos = this.locationInfoValues.map(x => {
                    return {...x, countryName: {[x.country]: true }}
                })
                this.locationInfos = locationInfos || [];
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        checkAndSetPrimeLocationAvailablity() {
            if (this.locationInfos?.find((location) => location?.primary)) {
                const primeLocationIndex = this.locationInfos?.findIndex((location) => location?.primary);
                this.locationInfos[primeLocationIndex].primary = false;

                return true;
            }
            return false;
        },
        onPage(event) {
            this.paginationData = {
                page: event?.page, // current page
                pageCount: event?.pageCount, // total page count
                rows: event?.rows, // Number of rows to display in new page
                columnOffset: event?.page * event?.rows,
            };
        },
        openNew() {
            this.locationInfo = {};
            this.submitted = false;
            this.locationDialog = true;
        },
        hideDialog() {
            this.locationDialog = false;
            this.submitted = false;
            this.editIndex = null;
        },
        editLocation(locationInfo, index) {
            this.locationInfo = { ...locationInfo };
            this.editIndex = this.paginationData?.columnOffset + index;
            this.locationDialog = true;
        },
        saveProduct() {
            this.submitted = true;
            this.locationDialog = false;
            this.locationInfo = {};
        },
        deleteLocation(locationInfo, index) {
            this.locationInfo = { ...locationInfo };
            this.deleteLocationInfoIndex = this.paginationData?.columnOffset + index;
            this.deleteLocationDialog = true;
        },
        onConfirmDeleteLocation() {
            this.locationInfos.splice(this.deleteLocationInfoIndex, 1);
            this.deleteLocationDialog = false;
            this.locationInfo = {};
            this.validationCall();
        },
        onLocationInfoSave() {
            if (!this.locationInfo.name || this.locationInfo.name.trim() === '') {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Error while creating data',
                    detail: 'Please enter name',
                    life: 3000,
                });
            }

            if (this.locationInfo?.primary) this.checkAndSetPrimeLocationAvailablity();

            if (this.locationInfo.name) this.locationInfo.name = this.locationInfo.name.trim();
            if (this.locationInfo.address1) this.locationInfo.address1 = this.locationInfo.address1.trim();
            if (this.locationInfo.address2) this.locationInfo.address2 = this.locationInfo.address2.trim();
            if (this.locationInfo.city) this.locationInfo.city = this.locationInfo.city.trim();
            if (this.locationInfo.region) this.locationInfo.region = this.locationInfo.region.trim();
            if (this.locationInfo.postalCode) this.locationInfo.postalCode = this.locationInfo.postalCode.trim();
            if (this.locationInfo.countryName) {
                let keys = Object.keys(this.locationInfo.countryName)
                if(keys.length)
                    this.locationInfo.country = keys[0];
                else
                    this.locationInfo.country = ''
            } else {
                this.locationInfo.country = ''
            }

            if (this.editIndex === null) {
                this.locationInfos = [...this.locationInfos, this.locationInfo];
            } else {
                this.locationInfos.splice(this.editIndex, 1, this.locationInfo);
                this.editIndex = null;
            }
            this.hideDialog();
            this.validationCall();
        },
        validationCall() {
            this.$emit('submit', this.v$.$silentErrors.length, this.locationInfos);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-multiselect) {
    margin: 0.75rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 2rem;
}
::v-deep(.p-inputtext) {
    margin: 0.75rem 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.add-new-btn {
    margin-top: 1.6rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
.field {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0.75rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.table-wrap {
    margin-top: 6rem;
    margin-bottom: 4rem;
}
.toolbar-wrap {
    width: 100%;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    margin-top: 2rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
</style>
