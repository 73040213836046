import getAxios from './header';

export async function getApi(path) {
    try {
        var response = await getAxios().get(`${process.env.VUE_APP_COMMON_BASE_URL + path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function postApi(path, payload) {
    try {
        var response = await getAxios().post(`${process.env.VUE_APP_COMMON_BASE_URL + path}`, payload);
        return response;
    } catch (err) {
        return err.response;
    }
}

export async function deleteApi(path) {
    try {
        var response = await getAxios().delete(`${process.env.VUE_APP_COMMON_BASE_URL + path}`);
        return response;
    } catch (err) {
        return err.response;
    }
}
