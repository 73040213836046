<template>
    <div>
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.number_active_agents }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.activeAgents.$model"
                    @input="changeHandler"
                    placeholder="Enter number of active agents"
                />
                <p v-for="error of v$.activeAgents.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value from 1 to 200,000' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">{{ FirmographicsLabel.number_sites }}</p>
                <InputText
                    class="input-text"
                    v-model="v$.noOfSites.$model"
                    @input="changeHandler"
                    placeholder="Enter number of sites"
                />
                <p v-for="error of v$.noOfSites.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value from 1 to 1000' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.number_countries }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.noOfcountries.$model"
                    @input="changeHandler"
                    placeholder="Enter number of countries with operations"
                />
                <p v-for="error of v$.noOfcountries.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value from 1 to 200' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.agent_tenure }}
                </p>
                <InputText
                    class="input-text"
                    v-model="v$.AvgTentureactiveAgents.$model"
                    @input="changeHandler"
                    placeholder="Enter average tenure of active agents"
                />
                <p v-for="error of v$.AvgTentureactiveAgents.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value (in years) from 1 to 50' }}
                </p>
            </div>

            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.business_model }}
                </p>
                <Dropdown
                    v-model="v$.businessModel.$model"
                    @change="changeHandler"
                    :options="businessModelOption"
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select business model"
                />
                <p v-for="error of v$.businessModel.$errors" :key="error.$uid" class="error-msg">
                    {{ 'Please enter numeric value of maximum length 15 characters' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">{{ FirmographicsLabel.geo_type }}</p>
                <MultiSelect
                    v-model="v$.selectedGeoType.$model"
                    @change="changeHandler"
                    :options="geoType"
                    optionLabel="name"
                    placeholder="Select geographical type"
                    display="chip"
                    :filter="true"
                />
                <p v-for="error of v$.selectedGeoType.$errors" :key="error.$uid" class="error-msg">
                    {{ 'At least select one' }}
                </p>
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.industries_verticals }}
                </p>
                <TreeSelect
                    v-model="industriesVerticals"
                    :options="industriesVerticalsOptions"
                    placeholder="Select industry verticals"
                    selectionMode="checkbox"
                    @change="onTreeSelect"
                    display="chip"
                    panelClass="p-tree-checkbox"
                />
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.industries_verticals_all }}
                </p>
                <TreeSelect
                    v-model="industriesVerticalsAll"
                    :options="industriesVerticalsOptions"
                    placeholder="Select industry verticals"
                    selectionMode="checkbox"
                    @change="onTreeSelect"
                    display="chip"
                    panelClass="p-tree-checkbox"
                />
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.other_industries_verticals }}
                </p>

                <Textarea
                    class="input-text"
                    type="text"
                    v-model="indusVerticalsList"
                    name="indusVerticalsList"
                    @input="changeHandler"
                    placeholder="Enter other industry verticals"
                    rows="5"
                    cols="30"
                />
                <FileUpload
                    @saved="onSaved"
                    :isImage="false"
                    :isAttachment="true"
                    :id="partnerId"
                    :fieldName="indusVerticalsListName"
                    :logoUrl="indusVerticalsListURL"
                />
            </div>
            <div class="p-col-12 p-md-6">
                <p class="field-title">
                    {{ FirmographicsLabel.countries_of_service }}
                    <Tooltip
                        description="The countries from which your agents serve clients, regardless whether you have an official business location or address there."
                    />
                </p>
                <TreeSelect
                    v-model="countries"
                    :options="countriesOptions"
                    placeholder="Select service provided from countries"
                    selectionMode="checkbox"
                    display="chip"
                    panelClass="p-tree-checkbox"
                    @change="onTreeSelect"
                />
            </div>
        </div>
    </div>
</template>
<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength, between } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';
import { convertCountryDataToTreeSelect, getTreeSelectInput } from '../../service/common';
import CountryService from '../../service/CountryService';
import { onMounted } from '@vue/runtime-core';
import Tooltip from '../Tooltip.vue';

// const positiveInt = (value) => {
//     if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
//     else return true;
// };


export default {
    name: 'Firmographics',
    components: {
        FileUpload,
        Tooltip
    },
    props: ['saveInd', 'FirmographicsValues'],
    data() {
        return {
            FirmographicsLabel: answerBankLabel.FirmographicsLabel,
            activeAgents: null,
            AvgTentureactiveAgents: null,
            noOfSites: null,
            noOfcountries: null,
            businessModel: null,
            selectedGeoType: null,
            industriesVerticalsAll: null,
            industriesVerticals: null,
            indusVerticalsList: null,
            indusVerticalsListName: null,
            indusVerticalsListURL: null,
            partnerId: null,
            geoType: [
                { name: 'Gig-Virtual' },
                { name: 'Global' },
                { name: 'Nearshore' },
                { name: 'Offshore' },
                { name: 'Domestic' },
            ],
            industriesVerticalsOptions: [],
            businessModelOption: [{ name: 'Center Based' }, { name: 'Remote' }, { name: 'Both' }],
            fileName: null,
            deleteFile: false,
            industrieParentInfos: [],
            countriesRegions: null,
            countries: null,
            countriesOptions: [],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            activeAgents: { betweenValue: between(0,200000), $autoDirty: true },
            AvgTentureactiveAgents: { 
                betweenValue: between(0,50),
                $autoDirty: true
            },
            noOfSites: { betweenValue: between(0,1000), $autoDirty: true },
            noOfcountries: { betweenValue: between(0,200), $autoDirty: true },
            industriesVerticalsAll: { minLength: minLength(1) },
            industriesVerticals: { minLength: minLength(1) },
            indusVerticalsList: { $autoDirty: true },
            businessModel: { minLength: minLength(1) },
            selectedGeoType: { minLength: minLength(1) },
        };
    },
    mounted() {
        const optionsArr = [
            'Communication Services: Broadband',
            'Communication Services: Cable',
            'Communication Services: Communications',
            'Communication Services: Entertainment and Gaming',
            'Communication Services: ISP',
            'Communication Services: Media/Publishing',
            'Communication Services: Telecom',
            'Communication Services: Wireless',
            'Communication Services: Wireline',
            'Communication Services: Other',
            'Consumer Discretionary: Automotive',
            'Consumer Discretionary: Car Rental',
            'Consumer Discretionary: Clothing/Fashion',
            'Consumer Discretionary: Consumer Services',
            'Consumer Discretionary: Durable Goods',
            'Consumer Discretionary: Ecommerce',
            'Consumer Discretionary: Fitness and Wellness',
            'Consumer Discretionary: Hospitality',
            'Consumer Discretionary: Legal',
            'Consumer Discretionary: Online Travel Agencies',
            'Consumer Discretionary: Retail',
            'Consumer Discretionary: Retail & Products',
            'Consumer Discretionary: Road Assistance',
            'Consumer Discretionary: Travel & Transportation',
            'Consumer Discretionary: Other',
            'Consumer Staples: Consumer Packaged Goods',
            'Consumer Staples: Food and Beverage',
            'Consumer Staples: Other',
            'Energy: Energy & Resources',
            'Energy: Mining',
            'Energy: Oil & Gas',
            'Energy: Other',
            'Financials: Banking',
            'Financials: Financial Services',
            'Financials: Insurance (non Healthcare)',
            'Financials: Investment',
            'Financials: Mortgage Services',
            'Financials: Other',
            'Health Care: Healthcare',
            'Health Care: Medical Equipment & Device',
            'Health Care: Payers/Insurance',
            'Health Care: Pharmaceutical',
            'Health Care: Providers',
            'Health Care: Other',
            'Industrials: Advertising and PR',
            'Industrials: Airline',
            'Industrials: Business Services',
            'Industrials: Digital Marketing',
            'Industrials: Human Resources',
            'Industrials: Manufacturing/Logistics',
            'Industrials: Professional Certification',
            'Industrials: Professional Services',
            'Industrials: Surveys',
            'Industrials: Transportation',
            'Industrials: Other',
            'Information Technology: Hardware',
            'Information Technology: Internet of Things (IoT)',
            'Information Technology: IT Services',
            'Information Technology: Software',
            'Information Technology: Other',
            'Materials: Chemical',
            'Materials: Construction/Renovation',
            'Materials: Other',
            'Public Sector: Education',
            'Public Sector: Federal Government',
            'Public Sector: Non-Profit',
            'Public Sector: Political Campaigns/Surveys',
            'Public Sector: Public Service',
            'Public Sector: State & Local Government',
            'Public Sector: Other',
            'Real Estate: Real Estate',
            'Real Estate: Other',
            'Utilities: Renewable Energy',
            'Utilities: Utilities',
            'Utilities: Other',
        ];

        const dataValues = getTreeSelectInput(optionsArr);

        this.industriesVerticalsOptions = dataValues.optionValues;
        this.industrieParentInfos = new Set(dataValues.parentInfos);
    },
    created() {
        this.countryService = new CountryService();
        onMounted(() => {
            this.countryService.getCountries().then((data) => {
                let countriesOptions = convertCountryDataToTreeSelect(data)
                this.countriesOptions = countriesOptions
                this.countriesRegions = new Set(Object.keys(data))
            });
        });
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        FirmographicsValues() {
            if (this.FirmographicsValues === null) {
                this.activeAgents = null;
                this.AvgTentureactiveAgents = null;
                this.noOfSites = null;
                this.noOfcountries = null;
                this.industriesVerticalsAll = null;
                this.industriesVerticals = null;
                this.indusVerticalsList = null;
                this.businessModel = null;
                this.selectedGeoType = null;
                this.countries = null;
                return;
            }
            try {
                this.FirmographicsValues?.forEach((element) => {
                    if (element.field_name === 'number_active_agents') this.activeAgents = element.json_value;
                    if (element.field_name === 'agent_tenure') this.AvgTentureactiveAgents = element.json_value;
                    if (element.field_name === 'number_sites') this.noOfSites = element.json_value;
                    if (element.field_name === 'number_countries') this.noOfcountries = element.json_value;
                    if (element.field_name === 'business_model') this.businessModel = element.json_value;
                    if (element.field_name === 'geo_type')
                        this.selectedGeoType = element.json_value?.map((ele) => ({ name: ele }));
                    if (element.field_name === 'industries_verticals') {
                        const dataObj = {};
                        element.json_value?.forEach((sl) => {
                            dataObj[sl] = { checked: true, partialChecked: false };
                        });
                        this.industriesVerticals = dataObj;
                    }
                    if (element.field_name === 'industries_verticals_all') {
                        const dataObj = {};

                        element.json_value?.forEach((sl) => {
                            dataObj[sl] = { checked: true, partialChecked: false };
                        });
                        this.industriesVerticalsAll = dataObj;
                    }
                    if (element.field_name === 'other_industries_verticals') {
                        this.indusVerticalsListName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.indusVerticalsList = element.json_value;
                        this.indusVerticalsListURL = element?.other?.attachments[0] || null;
                        if (this.indusVerticalsListURL) {
                            var splitLink = this.indusVerticalsListURL?.split('/');
                            this.fileName = splitLink[splitLink.length - 1];
                        }
                    }
                    if (element.field_name === 'countries_of_service') {
                        let countryObj = {}
                        element.json_value?.forEach(country => {
                            countryObj[country] = { checked: true }
                        })
                        this.countries = {...countryObj}
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;

            this.validationCall();
            this.$emit('isValid', true);
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        onTreeSelect() {
            this.validationCall();
            this.$emit('isValid', true);
        },
        validationCall() {
            this.FirmographicsValues?.forEach((element) => {
                if (element.field_name === 'number_active_agents') element.json_value = parseInt(this.activeAgents);
                if (element.field_name === 'agent_tenure') element.json_value = parseFloat(this.AvgTentureactiveAgents);
                if (element.field_name === 'number_sites') element.json_value = parseInt(this.noOfSites);
                if (element.field_name === 'number_countries') element.json_value = parseInt(this.noOfcountries);
                if (element.field_name === 'business_model') element.json_value = this.businessModel;
                if (element.field_name === 'geo_type') element.json_value = this.selectedGeoType?.map((ele) => ele.name);
                if (element.field_name === 'industries_verticals') {
                    if(!this.industriesVerticals) {
                        element.json_value = []
                        return;
                    }

                    element.json_value = Object.keys(this.industriesVerticals)
                            .filter(el => !this.industrieParentInfos.has(el))
                }
                if (element.field_name === 'industries_verticals_all') {
                    if(!this.industriesVerticalsAll) {
                        element.json_value = []
                        return;
                    }

                    element.json_value = Object.keys(this.industriesVerticalsAll)
                        .filter(el => !this.industrieParentInfos.has(el))
                        
                }
                if (element.field_name === 'other_industries_verticals')
                    element.json_value = this.stringCheck(this.indusVerticalsList);
                
                if (element.field_name === 'countries_of_service') {
                    if(!this.countries){
                        element.json_value = []
                        return;
                    }

                    element.json_value = Object.keys(this.countries)
                        .filter(country => !this.countriesRegions.has(country))
                }
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.FirmographicsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.link {
    position: relative;
    left: 1.5rem;
    top: 0.5rem;
    cursor: pointer;
}

.link a {
    color: blue;
}

.attach-file {
    color: #126a70;
    margin-left: 3rem;
}

.p-treeselect {
    margin-left: 1rem !important;
    max-width: 30rem !important;
}
</style>
