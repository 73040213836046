<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_messaging_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalMessageSys.$model"
                name="internalMessageSys"
                @input="changeEvent"
                placeholder="Enter Internal Messaging & Communication Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalMessageSysName"
                :logoUrl="internalMessageSysURL"
            />
            <p v-for="error of v$.internalMessageSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_kb_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalKnowledgeSys.$model"
                name="internalKnowledgeSys"
                @input="changeEvent"
                placeholder="Enter Internal Knowledge Base Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalKnowledgeSysName"
                :logoUrl="internalKnowledgeSysURL"
            />
            <p v-for="error of v$.internalKnowledgeSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_lms_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalLearningSys.$model"
                name="internalLearningSys"
                @input="changeEvent"
                placeholder="Enter Internal Learning Management Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalLearningSysName"
                :logoUrl="internalLearningSysURL"
            />
            <p v-for="error of v$.internalLearningSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_wfm_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalWorkforceSys.$model"
                name="internalWorkforceSys"
                @input="changeEvent"
                placeholder="Enter Internal Workforce Management Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalWorkforceSysName"
                :logoUrl="internalWorkforceSysURL"
            />
            <p v-for="error of v$.internalWorkforceSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_qa_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalQASys.$model"
                name="internalQASys"
                @input="changeEvent"
                placeholder="Enter Internal Quality Assurance (QA) Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalQASysName"
                :logoUrl="internalQASysURL"
            />
            <p v-for="error of v$.internalQASys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_voice_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalVoiceSys.$model"
                name="internalVoiceSys"
                @input="changeEvent"
                placeholder="Enter Internal Voice Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalVoiceSysName"
                :logoUrl="internalVoiceSysURL"
            />
            <p v-for="error of v$.internalVoiceSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_ivr_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalIVRSys.$model"
                name="internalIVRSys"
                @input="changeEvent"
                placeholder="Enter Internal Interactive Voice Response (IVR) Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalIVRSysName"
                :logoUrl="internalIVRSysURL"
            />
            <p v-for="error of v$.internalIVRSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_csat_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalCSATSys.$model"
                name="internalCSATSys"
                @input="changeEvent"
                placeholder="Enter Internal CSAT Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalCSATSysName"
                :logoUrl="internalCSATSysURL"
            />
            <p v-for="error of v$.internalCSATSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.internal_forecasting_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.internalForecastSys.$model"
                name="internalForecastSys"
                @input="changeEvent"
                placeholder="Enter Internal Forecasting Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="internalForecastSysName"
                :logoUrl="internalForecastSysURL"
            />
            <p v-for="error of v$.internalForecastSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_messaging_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientMessageSys.$model"
                name="clientMessageSys"
                @input="changeEvent"
                placeholder="Enter Client Messaging & Communications Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientMessageSysName"
                :logoUrl="clientMessageSysURL"
            />
            <p v-for="error of v$.clientMessageSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_kb_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientKnowledgeSys.$model"
                name="clientKnowledgeSys"
                @input="changeEvent"
                placeholder="Enter Client Knowledge Base Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientKnowledgeSysName"
                :logoUrl="clientKnowledgeSysURL"
            />
            <p v-for="error of v$.clientKnowledgeSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_lms_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientLearningSys.$model"
                name="clientLearningSys"
                @input="changeEvent"
                placeholder="Enter Client Learning Management Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientLearningSysName"
                :logoUrl="clientLearningSysURL"
            />
            <p v-for="error of v$.clientLearningSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_wfm_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientWorkforceSys.$model"
                name="clientWorkforceSys"
                @input="changeEvent"
                placeholder="Enter Client Workforce Management Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientWorkforceSysName"
                :logoUrl="clientWorkforceSysURL"
            />
            <p v-for="error of v$.clientWorkforceSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_qa_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientQASys.$model"
                name="clientQASys"
                @input="changeEvent"
                placeholder="Enter Client Quality Assurance (QA) Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientQASysName"
                :logoUrl="clientQASysURL"
            />
            <p v-for="error of v$.clientQASys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_voice_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientVoiceSys.$model"
                name="clientVoiceSys"
                @input="changeEvent"
                placeholder="Enter Client Voice Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientVoiceSysName"
                :logoUrl="clientVoiceSysURL"
            />
            <p v-for="error of v$.clientVoiceSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_ivr_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientIVRSys.$model"
                name="clientIVRSys"
                @input="changeEvent"
                placeholder="Enter Client Interactive Voice Response (IVR) Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientIVRSysName"
                :logoUrl="clientIVRSysURL"
            />
            <p v-for="error of v$.clientIVRSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_csat_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientCSATSys.$model"
                name="clientCSATSys"
                @input="changeEvent"
                placeholder="Enter Client CSAT Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientCSATSysName"
                :logoUrl="clientCSATSysURL"
            />
            <p v-for="error of v$.clientCSATSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">{{ SystemsLabel.client_forecasting_systems }}</p>
            <Textarea
                class="input-text"
                v-model="v$.clientForecastSys.$model"
                name="clientForecastSys"
                @input="changeEvent"
                placeholder="Enter Client Forecasting Systems"
                rows="5"
                cols="30"
                
            />
            <FileUpload
                @saved="onSaved"
                :isImage="false"
                :isAttachment="true"
                :id="partnerId"
                :fieldName="clientForecastSysName"
                :logoUrl="clientForecastSysURL"
            />
            <p v-for="error of v$.clientForecastSys.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
// import { alphaNum } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import FileUpload from '../common/FileUpload.vue';

export default {
    components: {
        FileUpload,
    },
    props: ['saveInd', 'SystemsValues'],
    data() {
        return {
            SystemsLabel: answerBankLabel.Systems,
            internalMessageSys: null,
            internalKnowledgeSys: null,
            internalLearningSys: null,
            internalWorkforceSys: null,
            internalQASys: null,
            internalVoiceSys: null,
            internalIVRSys: null,
            internalCSATSys: null,
            internalForecastSys: null,
            clientMessageSys: null,
            clientKnowledgeSys: null,
            clientLearningSys: null,
            clientWorkforceSys: null,
            clientQASys: null,
            clientVoiceSys: null,
            clientIVRSys: null,
            clientCSATSys: null,
            clientForecastSys: null,
            internalMessageSysName: null,
            internalKnowledgeSysName: null,
            internalLearningSysName: null,
            internalWorkforceSysName: null,
            internalQASysName: null,
            internalVoiceSysName: null,
            internalIVRSysName: null,
            internalCSATSysName: null,
            internalForecastSysName: null,
            clientMessageSysName: null,
            clientKnowledgeSysName: null,
            clientLearningSysName: null,
            clientWorkforceSysName: null,
            clientQASysName: null,
            clientVoiceSysName: null,
            clientIVRSysName: null,
            clientCSATSysName: null,
            clientForecastSysName: null,
            internalMessageSysURL: null,
            internalKnowledgeSysURL: null,
            internalLearningSysURL: null,
            internalWorkforceSysURL: null,
            internalQASysURL: null,
            internalVoiceSysURL: null,
            internalIVRSysURL: null,
            internalCSATSysURL: null,
            internalForecastSysURL: null,
            clientMessageSysURL: null,
            clientKnowledgeSysURL: null,
            clientLearningSysURL: null,
            clientWorkforceSysURL: null,
            clientQASysURL: null,
            clientVoiceSysURL: null,
            clientIVRSysURL: null,
            clientCSATSysURL: null,
            clientForecastSysURL: null,
            partnerId: null,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            internalMessageSys: { $autoDirty: true },
            internalKnowledgeSys: { $autoDirty: true },
            internalLearningSys: { $autoDirty: true },
            internalWorkforceSys: { $autoDirty: true },
            internalQASys: { $autoDirty: true },
            internalVoiceSys: { $autoDirty: true },
            internalIVRSys: { $autoDirty: true },
            internalCSATSys: { $autoDirty: true },
            internalForecastSys: { $autoDirty: true },
            clientMessageSys: { $autoDirty: true },
            clientKnowledgeSys: { $autoDirty: true },
            clientLearningSys: { $autoDirty: true },
            clientWorkforceSys: { $autoDirty: true },
            clientQASys: { $autoDirty: true },
            clientVoiceSys: { $autoDirty: true },
            clientIVRSys: { $autoDirty: true },
            clientCSATSys: { $autoDirty: true },
            clientForecastSys: { $autoDirty: true },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        SystemsValues() {
            if (this.SystemsValues === null) {
                this.internalMessageSys = null;
                this.internalKnowledgeSys = null;
                this.internalLearningSys = null;
                this.internalWorkforceSys = null;
                this.internalQASys = null;
                this.internalVoiceSys = null;
                this.internalIVRSys = null;
                this.internalCSATSys = null;
                this.internalForecastSys = null;
                this.clientMessageSys = null;
                this.clientKnowledgeSys = null;
                this.clientLearningSys = null;
                this.clientWorkforceSys = null;
                this.clientQASys = null;
                this.clientVoiceSys = null;
                this.clientIVRSys = null;
                this.clientCSATSys = null;
                this.clientForecastSys = null;
                return;
            }
            try {
                this.SystemsValues?.forEach((element) => {
                    if (element.field_name === 'internal_messaging_systems') {
                        this.internalMessageSys = element.json_value;
                        this.internalMessageSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalMessageSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_kb_systems') {
                        this.internalKnowledgeSys = element.json_value;
                        this.internalKnowledgeSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalKnowledgeSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_lms_systems') {
                        this.internalLearningSys = element.json_value;
                        this.internalLearningSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalLearningSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_wfm_systems') {
                        this.internalWorkforceSys = element.json_value;
                        this.internalWorkforceSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalWorkforceSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_qa_systems') {
                        this.internalQASys = element.json_value;
                        this.internalQASysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalQASysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_voice_systems') {
                        this.internalVoiceSys = element.json_value;
                        this.internalVoiceSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalVoiceSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_ivr_systems') {
                        this.internalIVRSys = element.json_value;
                        this.internalIVRSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalIVRSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_csat_systems') {
                        this.internalCSATSys = element.json_value;
                        this.internalCSATSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalCSATSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'internal_forecasting_systems') {
                        this.internalForecastSys = element.json_value;
                        this.internalForecastSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.internalForecastSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_messaging_systems') {
                        this.clientMessageSys = element.json_value;
                        this.clientMessageSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientMessageSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_kb_systems') {
                        this.clientKnowledgeSys = element.json_value;
                        this.clientKnowledgeSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientKnowledgeSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_lms_systems') {
                        this.clientLearningSys = element.json_value;
                        this.clientLearningSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientLearningSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_wfm_systems') {
                        this.clientWorkforceSys = element.json_value;
                        this.clientWorkforceSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientWorkforceSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_qa_systems') {
                        this.clientQASys = element.json_value;
                        this.clientQASysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientQASysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_voice_systems') {
                        this.clientVoiceSys = element.json_value;
                        this.clientVoiceSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientVoiceSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_ivr_systems') {
                        this.clientIVRSys = element.json_value;
                        this.clientIVRSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientIVRSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_csat_systems') {
                        this.clientCSATSys = element.json_value;
                        this.clientCSATSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientCSATSysURL = element?.other?.attachments[0] || null;
                    }
                    if (element.field_name === 'client_forecasting_systems') {
                        this.clientForecastSys = element.json_value;
                        this.clientForecastSysName = element.field_name;
                        this.partnerId = localStorage.getItem('accountId');
                        this.clientForecastSysURL = element?.other?.attachments[0] || null;
                    }
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeEvent(e) {
            if (e?.target?.tagName === 'TEXTAREA' && e?.target?.name) this[e.target.name] = e.target.value;
            this.$emit('isValid', true);
            this.validationCall();
        },
        stringCheck(value) {
            if (value !== null) {
                return value.toString().trim();
            }
        },
        validationCall() {
            this.SystemsValues?.forEach((element) => {
                if (element.field_name === 'internal_messaging_systems')
                    element.json_value = this.stringCheck(this.internalMessageSys);
                if (element.field_name === 'internal_kb_systems')
                    element.json_value = this.stringCheck(this.internalKnowledgeSys);
                if (element.field_name === 'internal_lms_systems')
                    element.json_value = this.stringCheck(this.internalLearningSys);
                if (element.field_name === 'internal_wfm_systems')
                    element.json_value = this.stringCheck(this.internalWorkforceSys);
                if (element.field_name === 'internal_qa_systems') element.json_value = this.stringCheck(this.internalQASys);
                if (element.field_name === 'internal_voice_systems') element.json_value = this.stringCheck(this.internalVoiceSys);
                if (element.field_name === 'internal_ivr_systems') element.json_value = this.stringCheck(this.internalIVRSys);
                if (element.field_name === 'internal_csat_systems') element.json_value = this.stringCheck(this.internalCSATSys);
                if (element.field_name === 'internal_forecasting_systems')
                    element.json_value = this.stringCheck(this.internalForecastSys);
                if (element.field_name === 'client_messaging_systems')
                    element.json_value = this.stringCheck(this.clientMessageSys);
                if (element.field_name === 'client_kb_systems') element.json_value = this.stringCheck(this.clientKnowledgeSys);
                if (element.field_name === 'client_lms_systems') element.json_value = this.stringCheck(this.clientLearningSys);
                if (element.field_name === 'client_wfm_systems') element.json_value = this.stringCheck(this.clientWorkforceSys);
                if (element.field_name === 'client_qa_systems') element.json_value = this.stringCheck(this.clientQASys);
                if (element.field_name === 'client_voice_systems') element.json_value = this.stringCheck(this.clientVoiceSys);
                if (element.field_name === 'client_ivr_systems') element.json_value = this.stringCheck(this.clientIVRSys);
                if (element.field_name === 'client_csat_systems') element.json_value = this.stringCheck(this.clientCSATSys);
                if (element.field_name === 'client_forecasting_systems')
                    element.json_value = this.stringCheck(this.clientForecastSys);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.SystemsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
