import { getAccountId } from './common';
import getAxios from './common/header';

export default class MarketingService {
    async getMarketing() {
        const accountId = getAccountId();
        try {
            var response = await getAxios().get(`${process.env.VUE_APP_COMMON_BASE_URL}v1/marketing?id=${accountId}`);
            return response;
        } catch (err) {
            console.error(err.stack);
            return err.response;
        }
    }

    async postMarketing(payload) {
        const accountId = getAccountId();
        try {
            var response = await getAxios().post(
                `${process.env.VUE_APP_COMMON_BASE_URL}v1/marketing?id=${accountId}`,
                JSON.parse(JSON.stringify(payload))
            );
            return response;
        } catch (err) {
            console.error(err.stack);
            return err.response;
        }
    }
}
