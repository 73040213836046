<script>
const FirmographicsLabel = {
    number_active_agents: 'Number of active agents',
    agent_tenure: 'What is the average tenure of active agents (please provide answer in years)?',
    number_sites: 'Number of sites',
    number_countries: 'Number of countries with operations',
    business_model: 'Business model (pick one)',
    geo_type: 'Geographical type',
    industries_verticals: 'Industries/verticals operating In',
    industries_verticals_all:
    'Please select all industries/verticals which you could provide either a case study or a reference from a current client',
    other_industries_verticals: 'Please list any other industries/verticals that you are currently operating in',
    countries_of_service: 'Service provided from countries',
};
const HRInfo = {
    percent_full_time_agents: 'Please identify the % of agents who are full-time ',
    percent_part_time_agents: 'Please identify the % of agents who are part-time ',
    percent_remote_agents: 'Please identify the % of agents who work remotely ',
    percent_center_based_agents: 'Please identify the % of agents who work from your offices ',
    shared_services: 'Please indicate whether your business offers shared services ',
    percent_shared_agents: 'Percentage of shared agents ',
    percent_dedicated_agents: 'Percentage of dedicated agents ',
    minimum_team_size: 'Please indicate the smallest team your company will provide ',
    average_team_size: 'Please indicate the average team size your company provides',
    maximum_team_size: 'Please indicate the largest team your company is able to provide',
    agents_can_hire_1_week: 'Number of agents that can be hired in 1 week ',
    agents_can_hire_2_week: 'Number of agents that can be hired in 2 weeks',
    agents_can_hire_3_week: 'Number of agents that can be hired in 3 weeks',
    agents_can_hire_4_week: 'Number of agents that can be hired in 1 month',
};
const Operations = {
    functions_performed: 'Functions performed',
    other_functions_performed: 'Other functions performed',
    language: 'Language',
    lang_serv: 'Languages served',
    language_levels_provided: 'Language levels provided',
    geographies_served: 'Geographies served',
    skillset_provided: 'Skillsets provided',
    channel_provided: 'Channels provided',
    operations_24_7: 'Do you currently support 24/7 operations?',
    hours_of_operation: 'Hours of operations',
};
const SystemAndCert = {
    datasec_certifications: 'Data security certifications (select all certifications held by your company)',
    industry_other_certifications: 'Other certifications (if applicable)',
    internal_crm_systems: 'Internal CRM systems',
    other_crm_systems: 'Other internal CRM systems (if applicable)',
    client_crm_systems: 'Client CRM systems',
    client_crm_systems_other: 'Other client CRM systems (if applicable)',
};
const PricingModelsAndGuide = {
    pricing_models_available: 'Pricing models available',
    average_hourly_rate_non_tech: 'Average hourly rate for non-technical services',
    average_hourly_rate_tech_t2: 'Average hourly rate for tech tier 1 services',
    average_hourly_rate_tech_t3: 'Average hourly rate for tech tier 2 services',
    average_hourly_rate_tech_t4: 'Average hourly rate for tech tier 3 services',
    overtime_multiplier: 'Overtime multiplier',
    observed_holiday_multiplier: 'Observed holiday multiplier',
    training_discount_percent: 'Training discount percentage (if applicable)',
};
const IndustriesVerticals = {
    industries_verticals_1_1: 'Please select the top industry/vertical that represents the highest percent of your business',
    industries_verticals_1_2: 'Please provide the amount of business this largest vertical represents',
    industries_verticals_2_1: 'Please select the industry/vertical that represents the second highest percent of your business',
    industries_verticals_2_2: 'Please provide the amount of business this second largest vertical represents',
    industries_verticals_3_1: 'Please select the industry/vertical that represents the third highest percent of your business',
    industries_verticals_3_2: 'Please provide the amount of business this third largest vertical represents',
    number_of_clients: 'Please provide the number of clients you currently serve',
};
const staffManagement = {
    agents_receive_health_insurance: 'Do your agents receive health insurance?',
    agent_turnover_percent: 'Percentage of agent turnover',
    turnover_calculation: 'Please define how you calculate agent turnover',
    agent_retention:
        'Describe programs designed to facilitate employee retention inclusive of employee morale, recognition, and overall management of turnover',
    current_customers: 'If you are able, please provide a few client names you currently serve',
    culture: "In a few sentences, please describe your company's culture",
    leadership_team_tenure: 'Leadership team tenure in years (average)',
    site_square_feet: 'Size of site (in square Feet)',
    site_square_feet_per_agent: 'Size of agent workspace (in square feet)',
    workstation_unused_capacity: 'Open workstations / available capacity',
};
const FunctionsPerformed = {
    functions_performed_1_1: 'Please select the top function performed that represents the highest percent of your business',
    functions_performed_1_2: 'Please provide the amount of business this largest function performed represents',
    functions_performed_2_1: 'Please select the function performed that represents the second highest percent of your business',
    functions_performed_2_2: 'Please provide the amount of business this second largest function performed represents',
    functions_performed_3_1: 'Please select the function performed that represents the third highest percent of your business',
    functions_performed_3_2: 'Please provide the amount of business this third largest function performed represents',
    observed_holidays: 'Observed holidays',
};
const ReportingAndQA = {
    reporting: 'Do you have real-time reporting dashboards your clients can view?',
    reporting_customized: 'Explain your ad hoc reporting capabilities',
    reporting_arenacx: 'Do you have APIs available in your systems that ArenaCX can access to display dashboards for customers?',
    qa_process: 'Please describe your internal QA processes. Please include any differences between voice and non-voice channels',
    qa_rubric: 'Do you have a standard QA rubric?',
    qa_remote_monitoring: 'Do you have remote monitoring capabilities?',
    qa_remote_monitoring_clients: 'Can clients dial in at any time?',
    qa_call_recording: 'Do you digitally record all calls?',
    qa_call_recording_percent: 'If not, what percentage?',
    qa_call_monitoring_process:
        'Describe processes for call monitoring and expected and realized benefits of the interaction monitoring process',
    qa_agent_feedback: 'Describe the agent feedback process and monitoring thereafter',
    quality_assurance_rubric: 'QA rubric (upload)',
    average_agents_per_qa_admin: 'Average number of agents per QA admin',
};
const WFManagement = {
    wfm_demand_planning_tool: 'Workforce management (WFM) — demand planning tool',
    wfm_capacity_planning_tool: 'Workforce management (WFM) — capacity planning tool',
    wfm_process_description: 'Workforce management (WFM) — process description',
    projmgmt_dedicated_launch_pm: 'Project management — dedicated launch PM',
    projmgmt_process: 'Please describe your implementation process and typical implementation timeline',
    projmgmt_standard_template: 'Project management — standard template',
    projmgmt_template: 'Project management — template attached',
    change_process: 'Please describe your internal processes for change management',
};
const WorkVolume = {
    annual_interactions_per_demand_phone: 'Phone: approximate annual interactions',
    annual_interactions_per_demand_chat: 'Chat: approximate annual interactions',
    annual_interactions_per_demand_email_webform: 'Email/Webform: approximate annual interactions',
    annual_interactions_per_demand_social: 'Social media: approximate annual interactions',
    annual_interactions_per_demand_sms: 'SMS/Text: approximate annual interactions',
    annual_interactions_per_demand_video: 'Video: approximate annual interactions',
    annual_interactions_per_demand_other: 'All other channels: approximate annual interactions (if applicable)',
    interactions_inbound: 'Across all channels, what percentage of interactions are inbound?',
    interactions_outbound: 'Across all channels, what percentage of interactions are outbound?',
    average_score_csat: 'Average CSAT score',
    average_score_sla_compliance: 'Average SLA compliance score',
};
const ClientManagement = {
    average_client_tenure_years: 'Average client tenure (in years)',
    average_agents_per_team_lead: 'Average number of agents per team lead',
    lead_supervisor_process: 'Please describe the supervisor/team lead procedures',
};
const TraningAndImprov = {
    average_agents_per_trainer: 'Average number of agents per trainer',
    number_of_trainers: 'Please provide the number of dedicated trainers for your organization',
    trainer_requirements: 'Please describe the requirements needed to become a dedicated trainer',
    training_program: 'Please describe your new agent training',
    new_agent_training_duration: 'How long is your new agent training?',
    training_strategy: 'Describe your training strategy including all delivery methods for content facilitation',
    training_services:
        'Describe the training services you provide to clients, inclusive of content management and curriculum development processes',
    training_effectiveness: 'Describe in detail your processes for measuring the effectiveness of training',
    training_gaps:
        'Describe how you identify knowledge and/or skills gaps within your employee base and your process for course recovery',
};
const Resiliency = {
    // diversity_certification_doc: 'Diversity certification (if applicable)',
    has_bcp: 'Do you have a documented business continuity plan?',
    bcp_description: 'Please describe your business continuity plan/disaster recovery plan',
    systems_redundancy: 'Describe your systems redundancy and backup',
    systems_redundancy_power: 'Do you have a backup generator at each site?',
    systems_redundancy_connectivity: 'Do you have redundant internet and telecom providers?',
    systems_redundancy_it_support: 'What hours is your IT center staffed?',
    systems_redundancy_it_support_personnel: 'How many people do you have in your systems department?',
    bcp_downtime:
        'How much downtime have you experienced in the past 12 months due to systems, electrical, and weather-related incidents? (please provide answer in days)',
    bcp_document: 'Business continuity plan (if applicable)',
};
const SocialRep = {
    certification_diverse_explanation: 'Is your firm Certified Diverse as...',
    noncertification_diverse_explanation: 'Is your firm otherwise Diverse for being...',
    socialrep_cert_body_placeholder: 'Name of certifying body...',
    socialrep_explanation_placeholder: 'Explanation...',
    socialrep_explanation_detail_optional: 'Additional detail...',

    cert_diverse_header: 'Impact by Ownership',
    cert_diverse_woman_owned: '...Woman-Owned?',
    cert_diverse_minority_owned: '...Minority-Owned?',
    cert_diverse_disabled_owned: '...Disabled-Owned?',
    cert_diverse_veteran_owned: '...Veteran-Owned?',
    cert_diverse_other_owned: '...otherwise "Owner" certified?',
    cert_diverse_how_meet: 'How do you meet the certification requirements?',
    cert_diverse_certifying_body: 'Certifying body (attach proof of certification)',

    noncert_diverse_faith_owned: '...Faith-Owned/Faith-Based?',
    noncert_diverse_faith_owned_summary: 'Provide some additional detail (optional)',
    noncert_diverse_other: '...Diverse in some other regard?',
    noncert_diverse_other_summary: 'Provide some additional detail (optional)',

    econ_disadvantaged_header: 'Impact by Location',
    econ_disadvantaged_explanation: 'These pertain to programs supporting regions characterized by high unemployment rates, low median household incomes, and/or high poverty rates, identified by the local or national government as economically distressed and eligible for special assistance.',
    econ_disadvantaged_program_yes: 'Do you have such a program?',
    econ_disadvantaged_locations: "Name the location(s), and describe your program",
    econ_disadvantaged_quals_and_certs: "Qualifications and certifications (attach evidence)",

    employ_programs_header: 'Impact by Employment',
    employ_programs_explanation: 'These pertain to the existence of formal programs within your firm to hire and support agents from economically disadvantaged populations. These programs can help business meet and exceed cost and quality objectives — and provide an attractive option to oursourcing clients.',
    employ_program_do_you_have: 'Do you have such a program?',
    employ_programs_which_populations: 'Which populations do you have programs for?',
    employ_programs_placeholder: 'Select populations...',
    employ_programs_which_populations_details: 'Provide details, clarifications, specifics, etc.',
    employ_programs_details_placeholder: 'Details of program...',

    social_resp_header: 'Social Responsibility',
    culture_give_back: 'Please describe how you give back to the communities in which you operate?',
    charitable_organizations: 'What charities or philanthropic organizations do you support or participate in?',
    company_values: 'Please describe your company values',
    mission_vision: 'Please describe your mission/vision',
    has_csr_plan: 'Do you have a documented corporate social responsibility plan?',
    csr_plan_attached: 'Corporate social responsibility plan (if applicable)',


};
const Systems = {
    internal_messaging_systems: 'Internal messaging & communication systems',
    internal_kb_systems: 'Internal knowledge base systems',
    internal_lms_systems: 'Internal learning management systems',
    internal_wfm_systems: 'Internal workforce management systems',
    internal_qa_systems: 'Internal quality assurance (QA) systems',
    internal_voice_systems: 'Internal voice systems',
    internal_ivr_systems: 'Internal interactive voice response (IVR) systems',
    internal_csat_systems: 'Internal CSAT systems',
    internal_forecasting_systems: 'Internal forecasting systems',
    client_messaging_systems: 'Client messaging & communications systems',
    client_kb_systems: 'Client knowledge base systems',
    client_lms_systems: 'Client learning management systems',
    client_wfm_systems: 'Client workforce management systems',
    client_qa_systems: 'Client quality assurance (QA) systems',
    client_voice_systems: 'Client voice systems',
    client_ivr_systems: 'Client interactive voice response (IVR) systems',
    client_csat_systems: 'Client CSAT systems',
    client_forecasting_systems: 'Client forecasting systems',
};
const AccManagement = {
    account_management_processes: 'Describe your account management process',
    no_accmanager_accounts: 'How many accounts does one account manager handle?',
    account_management_personnel: 'How large is your client services group (number of managers)',
    account_management_problem_resolution: 'Please describe your problem resolution process for escalated client issues',
    account_management_process_change: 'Describe your change management process and practices',
    account_management_client_visitation:
        'Do you allow and provide space for customers to have on-site temporary visits (if applicable)?',
    account_management_accounts_number: 'How many accounts does one account manager handle?',
};

export default {
    FirmographicsLabel,
    HRInfo,
    Operations,
    SystemAndCert,
    PricingModelsAndGuide,
    IndustriesVerticals,
    staffManagement,
    FunctionsPerformed,
    ReportingAndQA,
    WFManagement,
    WorkVolume,
    ClientManagement,
    TraningAndImprov,
    Resiliency,
    SocialRep,
    Systems,
    AccManagement,
};
</script>
