<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_1_1 }}
            </p>
            <Dropdown
                v-model="v$.indusVerticalsHighPCT.$model"
                @change="changeHandler"
                :options="industriesVerticalsOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the highest percent of your business"
                filter
            />
            <p v-for="error of v$.indusVerticalsHighPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>
        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_1_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.largeVerticalRep.$model"
                    @input="changeHandler"
                    placeholder="Enter largest amount of business"
                />
            </div>
            <p v-for="error of v$.largeVerticalRep.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_2_1 }}
            </p>
            <Dropdown
                v-model="v$.indusVerticalSecPCT.$model"
                @change="changeHandler"
                :options="industriesVerticalsOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the second highest percent of your business"
                filter
            />
            <p v-for="error of v$.indusVerticalSecPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_2_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.seclargeVerticalRep.$model"
                    @input="changeHandler"
                    placeholder="Enter second largest amount of business"
                />
            </div>
            <p v-for="error of v$.seclargeVerticalRep.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_3_1 }}
            </p>
            <Dropdown
                v-model="v$.indusVerticalThirdPCT.$model"
                @change="changeHandler"
                :options="industriesVerticalsOptions"
                optionLabel="name"
                optionValue="name"
                placeholder="Select the third highest percent of your business"
                filter
            />
            <p v-for="error of v$.indusVerticalThirdPCT.$errors" :key="error.$uid" class="error-msg">
                {{ 'At least select one' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.industries_verticals_3_2 }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.thirdlargeVerticalRep.$model"
                    @input="changeHandler"
                    placeholder="Enter third largest amount of business"
                />
            </div>
            <p v-for="error of v$.thirdlargeVerticalRep.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter decimal value of maximum length 15 characters' }}
            </p>
        </div>

        <div class="p-col-12 p-md-6">
            <p class="field-title">
                {{ IndustriesVerticalsLabel.number_of_clients }}
            </p>
            <div class="p-inputgroup">
                <InputText
                    class="input-text"
                    v-model="v$.noClientServ.$model"
                    @input="changeHandler"
                    placeholder="Enter number of clients you currently serve"
                />
            </div>
            <p v-for="error of v$.noClientServ.$errors" :key="error.$uid" class="error-msg">
                {{ 'Please enter numeric value of maximum length 15 characters' }}
            </p>
        </div>
    </div>
</template>

<script>
import answerBankLabel from '../constants/AnswerBankCons.vue';
import { minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

const positiveInt = (value) => {
    if (value && (value.length > 15 || value < 0 || !/^[1-9]\d*$/.test(value))) return false;
    else return true;
};

const positiveDecimal = (value) => {
    if (value && (value.length > 16 || value < 0 || !/^(0|[1-9]\d*)(\.\d+)?$/.test(value))) return false;
    else return true;
};

export default {
    props: ['saveInd', 'IndustriesVerticalsValues'],
    data() {
        return {
            IndustriesVerticalsLabel: answerBankLabel.IndustriesVerticals,
            indusVerticalsHighPCT: null,
            largeVerticalRep: null,
            indusVerticalSecPCT: null,
            seclargeVerticalRep: null,
            indusVerticalThirdPCT: null,
            thirdlargeVerticalRep: null,
            noClientServ: null,
            industriesVerticalsOptions: [
                { name: 'Communication Services: Broadband' },
                { name: 'Communication Services: Cable' },
                { name: 'Communication Services: Communications' },
                { name: 'Communication Services: Entertainment and Gaming' },
                { name: 'Communication Services: ISP' },
                { name: 'Communication Services: Media/Publishing' },
                { name: 'Communication Services: Telecom' },
                { name: 'Communication Services: Wireless' },
                { name: 'Communication Services: Wireline' },
                { name: 'Communication Services: Other' },
                { name: 'Consumer Discretionary: Automotive' },
                { name: 'Consumer Discretionary: Car Rental' },
                { name: 'Consumer Discretionary: Clothing/Fashion' },
                { name: 'Consumer Discretionary: Consumer Services' },
                { name: 'Consumer Discretionary: Durable Goods' },
                { name: 'Consumer Discretionary: Ecommerce' },
                { name: 'Consumer Discretionary: Fitness and Wellness' },
                { name: 'Consumer Discretionary: Hospitality' },
                { name: 'Consumer Discretionary: Legal' },
                { name: 'Consumer Discretionary: Online Travel Agencies' },
                { name: 'Consumer Discretionary: Retail' },
                { name: 'Consumer Discretionary: Retail & Products' },
                { name: 'Consumer Discretionary: Road Assistance' },
                { name: 'Consumer Discretionary: Travel & Transportation' },
                { name: 'Consumer Discretionary: Other' },
                { name: 'Consumer Staples: Consumer Packaged Goods' },
                { name: 'Consumer Staples: Food and Beverage' },
                { name: 'Consumer Staples: Other' },
                { name: 'Energy: Energy & Resources' },
                { name: 'Energy: Mining' },
                { name: 'Energy: Oil & Gas' },
                { name: 'Energy: Other' },
                { name: 'Financials: Banking' },
                { name: 'Financials: Financial Services' },
                { name: 'Financials: Insurance (non Healthcare)' },
                { name: 'Financials: Investment' },
                { name: 'Financials: Mortgage Services' },
                { name: 'Financials: Other' },
                { name: 'Health Care: Healthcare' },
                { name: 'Health Care: Medical Equipment & Device' },
                { name: 'Health Care: Payers/Insurance' },
                { name: 'Health Care: Pharmaceutical' },
                { name: 'Health Care: Providers' },
                { name: 'Health Care: Other' },
                { name: 'Industrials: Advertising and PR' },
                { name: 'Industrials: Airline' },
                { name: 'Industrials: Business Services' },
                { name: 'Industrials: Digital Marketing' },
                { name: 'Industrials: Human Resources' },
                { name: 'Industrials: Manufacturing/Logistics' },
                { name: 'Industrials: Professional Certification' },
                { name: 'Industrials: Professional Services' },
                { name: 'Industrials: Surveys' },
                { name: 'Industrials: Transportation' },
                { name: 'Industrials: Other' },
                { name: 'Information Technology: Hardware' },
                { name: 'Information Technology: Internet of Things (IoT)' },
                { name: 'Information Technology: IT Services' },
                { name: 'Information Technology: Software' },
                { name: 'Information Technology: Other' },
                { name: 'Materials: Chemical' },
                { name: 'Materials: Construction/Renovation' },
                { name: 'Materials: Other' },
                { name: 'Public Sector: Education' },
                { name: 'Public Sector: Federal Government' },
                { name: 'Public Sector: Non-Profit' },
                { name: 'Public Sector: Political Campaigns/Surveys' },
                { name: 'Public Sector: Public Service' },
                { name: 'Public Sector: State & Local Government' },
                { name: 'Public Sector: Other' },
                { name: 'Real Estate: Real Estate' },
                { name: 'Real Estate: Other' },
                { name: 'Utilities: Renewable Energy' },
                { name: 'Utilities: Utilities' },
                { name: 'Utilities: Other' },
            ],
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            indusVerticalsHighPCT: { minLength: minLength(1) },
            largeVerticalRep: { positiveDecimal },
            indusVerticalSecPCT: { minLength: minLength(1) },
            seclargeVerticalRep: { positiveDecimal },
            indusVerticalThirdPCT: { minLength: minLength(1) },
            thirdlargeVerticalRep: { positiveDecimal },
            noClientServ: { positiveInt },
        };
    },
    watch: {
        v$: function () {
            if (this.v$.$dirty) {
                this.validationCall();
            }
        },
        saveInd() {
            if (this.saveInd) {
                this.validationCall();
            }
        },
        IndustriesVerticalsValues() {
            if (this.IndustriesVerticalsValues === null) {
                this.indusVerticalsHighPCT = null;
                this.largeVerticalRep = null;
                this.indusVerticalSecPCT = null;
                this.seclargeVerticalRep = null;
                this.indusVerticalThirdPCT = null;
                this.thirdlargeVerticalRep = null;
                this.noClientServ = null;
                return;
            }
            try {
                this.IndustriesVerticalsValues?.forEach((element) => {
                    if (element.field_name === 'industries_verticals_1_1') this.indusVerticalsHighPCT = element.json_value;
                    if (element.field_name === 'industries_verticals_1_2') this.largeVerticalRep = element.json_value;
                    if (element.field_name === 'industries_verticals_2_1') this.indusVerticalSecPCT = element.json_value;
                    if (element.field_name === 'industries_verticals_2_2') this.seclargeVerticalRep = element.json_value;
                    if (element.field_name === 'industries_verticals_3_1') this.indusVerticalThirdPCT = element.json_value;
                    if (element.field_name === 'industries_verticals_3_2') this.thirdlargeVerticalRep = element.json_value;
                    if (element.field_name === 'number_of_clients') this.noClientServ = element.json_value;
                });
            } catch (err) {
                console.error('Error', err.stack);
            }
        },
    },
    methods: {
        changeHandler() {
            this.$emit('isValid', true);

            this.validationCall();
        },
        validationCall() {
            this.IndustriesVerticalsValues?.forEach((element) => {
                if (element.field_name === 'industries_verticals_1_1') element.json_value = this.indusVerticalsHighPCT;
                if (element.field_name === 'industries_verticals_1_2') element.json_value = parseFloat(this.largeVerticalRep);
                if (element.field_name === 'industries_verticals_2_1') element.json_value = this.indusVerticalSecPCT;
                if (element.field_name === 'industries_verticals_2_2') element.json_value = parseFloat(this.seclargeVerticalRep);
                if (element.field_name === 'industries_verticals_3_1') element.json_value = this.indusVerticalThirdPCT;
                if (element.field_name === 'industries_verticals_3_2')
                    element.json_value = parseFloat(this.thirdlargeVerticalRep);
                if (element.field_name === 'number_of_clients') element.json_value = parseInt(this.noClientServ);
            });

            this.$emit('submit', this.v$.$silentErrors.length, this.IndustriesVerticalsValues);
            this.v$.$touch();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown) {
    margin-left: 1rem;
    max-width: 30rem;
}
::v-deep(.p-multiselect) {
    margin-left: 1rem;
    max-width: 30rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.add-new-btn {
    margin-top: 1.4rem !important;
    width: 8rem !important;
    background: #00796b !important;
    border: 2px solid #00796b !important;
    display: block;
    float: right !important;
}
::v-deep(.p-button.p-button-outlined) {
    background-color: transparent !important;
    color: #00796b !important;
    border: 1px solid;
}
::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    background-color: #d6d6d6;
    height: 3rem;
}

::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem !important;
    margin: 0.5rem;
    background: #ffffff !important;
    border: none !important;
    display: flex;
    float: left !important;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded):hover {
    background: #e4edd9 !important;
}
::v-deep(.p-datatable) {
    .p-datatable-thead > tr > th {
        color: #707683 !important;
        background: #fbfbfb !important;
        transition: none;
    }
}
::v-deep(.p-calendar-w-btn .p-datepicker-trigger.p-button) {
    background-color: transparent !important;
    border: 0 none !important;
    margin: 0 !important;
}
.table-wrap {
    margin-top: 5rem;
}
.toolbar-wrap {
    width: 100%;
    height: 3rem;
    background: #e4edd9;
    align-self: center;
    padding: 0.75rem;
    border-radius: 0.25rem;
    margin-top: 2rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 30rem;
}
.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
